import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../utils";

// create slice
const baseUrl = import.meta.env.VITE_REACT_APP_API_URL;
const sliceName = "accessData";


// APIs

//Users
/* table Meta Data */
export const getUsersMetaData = createAsyncThunk(
  `${sliceName}/getUsersMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/user/getMetadata`)
);
export const deleteUsers = createAsyncThunk(
  `${sliceName}/deleteUsers`,
  async ({ id }) => {
    const res = await fetchWrapper.post(`${baseUrl}/user/delete/${id}`, { id });
    return res || res === "" ? { id } : null;
  }
);
export const getUsersRowData = createAsyncThunk(
  `${sliceName}/getUsersRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/users?${params}`)
);
/* table Meta Data */
export const getAllUsers = createAsyncThunk(
  `${sliceName}/getAllUsers`,
  async () => await fetchWrapper.get(`${baseUrl}/users`)
);

export const getUserById= createAsyncThunk(
  `${sliceName}/getUserById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/users/${id}`)
);

export const editUser = createAsyncThunk(
  `${sliceName}/editUser`,
  async ( values ) => await fetchWrapper.patch(`${baseUrl}/users/${values.id}`, values )
);

// export const addUser = createAsyncThunk(
//   `${sliceName}/addUser`,
//   async (values) => await fetchWrapper.post(`${baseUrl}/users`, values)
// );

export const deleteUser = createAsyncThunk(
  `${sliceName}/deleteUser`,
  async ({ userId }) => await fetchWrapper.delete(`${baseUrl}/users/${userId}`, { userId })
);

//Permission
/* table Meta Data */
export const getPermissionsMetaData = createAsyncThunk(
  `${sliceName}/getPermissionsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/permission/getMetadata`)
);

export const getPermissionsRowData = createAsyncThunk(
  `${sliceName}/getPermissionsRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/permission/getAll?${params}`)
);
/* table Meta Data */

export const getAllPermissions = createAsyncThunk(
  `${sliceName}/getAllPermissions`,
  async () => await fetchWrapper.get(`${baseUrl}/permission/get`)
);

export const getPermissionById= createAsyncThunk(
  `${sliceName}/getPermissionById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/permission/get/${id}`)
);

export const editPermission = createAsyncThunk(
  `${sliceName}/editPermission`,
  async ( values ) => await fetchWrapper.post(`${baseUrl}/permission/update/${values.permissionId}`, values )
);

export const addPermission = createAsyncThunk(
  `${sliceName}/addPermission`,
  async (values) => await fetchWrapper.post(`${baseUrl}/permission/add`, values)
);

export const deletePermission = createAsyncThunk(
  `${sliceName}/deletePermission`,
  async ({ permissionId }) => fetchWrapper.post(`${baseUrl}/permission/delete/${permissionId}`, { permissionId })
);

// Roles
/* table Meta Data */
export const getRolesMetaData = createAsyncThunk(
  `${sliceName}/getRolesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/role/getMetadata`)
);

export const getRolesRowData = createAsyncThunk(
  `${sliceName}/getRolesRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/role/getAll?${params}`)
);
/* table Meta Data */
export const getAllRoles = createAsyncThunk(
  `${sliceName}/getAllRoles`,
  async () => await fetchWrapper.get(`${baseUrl}/role/get`)
);

export const getRoleById= createAsyncThunk(
  `${sliceName}/getRoleById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/role/get/${id}`)
);

export const editRole = createAsyncThunk(
  `${sliceName}/editRole`,
  async (values) => await fetchWrapper.post(`${baseUrl}/role/update/${values.roleId}`, values)
);

export const addRole = createAsyncThunk(
  `${sliceName}/addRole`,
  async (values) => await fetchWrapper.post(`${baseUrl}/role/add`, values)
);

export const deleteRole = createAsyncThunk(
  `${sliceName}/deleteRole`,
  async ({ roleId }) => await fetchWrapper.post(`${baseUrl}/role/delete/${roleId}`, { roleId })
);
//User-Location
/* table Meta Data */
export const getUserLocationMetaData = createAsyncThunk(
  `${sliceName}/getUserLocationMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/user-location-maps/getMetadata`)
);
export const getUserLocationRowData = createAsyncThunk(
  `${sliceName}/getUserLocationRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/user-location-maps/getAll?${params}`)
);
export const getUserAllLocation = createAsyncThunk(
  `${sliceName}/getUserAllLocation`,
  async () => await fetchWrapper.get(`${baseUrl}/location/getAll?filter=`)
);
export const getUserLocationById= createAsyncThunk(
  `${sliceName}/getUserLocationById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/user-location-maps/get/${id}`)
);
export const deleteUserLocation = createAsyncThunk(
  `${sliceName}/deleteUserLocation`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/user-location-maps/delete/${id}`, { id })
);
export const addUserLocation = createAsyncThunk(
  `${sliceName}/addUserLocation`,
  async (values) => await fetchWrapper.post(`${baseUrl}/user-location-maps/add`, values)
);
export const editUserLocation = createAsyncThunk(
  `${sliceName}/editUserLocation`,
  async (values) => await fetchWrapper.post(`${baseUrl}/user-location-maps/update/${values.id}`, values)
);


// Role Permissions
/* table Meta Data */
export const getRolePermissionsMetaData = createAsyncThunk(
  `${sliceName}/getRolePermissionsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/role-permissions/getMetadata`)
);

export const getRolePermissionsRowData = createAsyncThunk(
  `${sliceName}/getRolePermissionsRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/role-permissions/getAll?${params}`)
);
/* table Meta Data */
export const getAllRolePermissions = createAsyncThunk(
  `${sliceName}/getRolePermissions`,
  async () => await fetchWrapper.get(`${baseUrl}/role-permissions/get`)
);

export const getRolePermissionById= createAsyncThunk(
  `${sliceName}/getRoleById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/role-permissions/get/${id}`)
);

export const editRolePermission = createAsyncThunk(
  `${sliceName}/editRolePermission`,
  async (values) => await fetchWrapper.post(`${baseUrl}/role-permissions/update/${values.id}`, values)
);

export const addRolePermission = createAsyncThunk(
  `${sliceName}/addRolePermission`,
  async (values) => await fetchWrapper.post(`${baseUrl}/role-permissions/add`, values)
);

export const deleteRolePermission = createAsyncThunk(
  `${sliceName}/deleteRolePermission`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/role-permissions/delete/${id}`, { id })
);

//User Role
/* table Meta Data */
export const getUserRolesMetaData = createAsyncThunk(
  `${sliceName}/getUserRolesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/user-roles/getMetadata`)
);

export const getUserRolesRowData = createAsyncThunk(
  `${sliceName}/getUserRolesRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/user-roles/getAll?${params}`)
);
/* table Meta Data */
export const getAllUserRoles = createAsyncThunk(
  `${sliceName}/getAllUserRoles`,
  async () => await fetchWrapper.get(`${baseUrl}/user-roles/get`)
);

export const getUserRoleById= createAsyncThunk(
  `${sliceName}/getuserRoleById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/user-roles/get/${id}`)
);

export const editUserRole = createAsyncThunk(
  `${sliceName}/editUserRole`,
  async (values) => await fetchWrapper.post(`${baseUrl}/user-roles/update/${values.id}`, values)
);

export const addUserRole = createAsyncThunk(
  `${sliceName}/addUserRole`,
  async (values) => await fetchWrapper.post(`${baseUrl}/user-roles/add`, values)
);

export const deleteUserRole = createAsyncThunk(
  `${sliceName}/deleteUserRole`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/user-roles/delete/${id}`, { id })
);

// slice
export const accessSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default accessSlice.reducer;
