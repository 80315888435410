import { useState } from 'react'
import reactLogo from './assets/react.svg'
import viteLogo from '/vite.svg'
import './App.css'
import Providers from '@components/Providers'

import RootLayout from './app/layouts/RootLayout'

import Login from '@/views/Login'

import { BrowserRouter } from "react-router-dom";
import { AppRouter } from './routes/AppRouter'

function App() {

  return (
    <Providers direction={`ltr`}>
      <BrowserRouter>
        <AppRouter/>
      </BrowserRouter>
      {/* <RootLayout params>
        <Login mode="system"/>
      </RootLayout> */}
    </Providers>
    
  )
}

export default App
