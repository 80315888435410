const LayoutHorizontal = props => {
  return (
    <svg width='104' height='66' viewBox='0 0 104 66' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='104' height='66' rx='4' fill='currentColor' fillOpacity='0.02' />
      <rect x='44.0068' y='19.6136' width='46.8212' height='17.6' rx='2' fill='currentColor' fillOpacity='0.08' />
      <rect x='14.9854' y='19.6136' width='22.1679' height='17.6' rx='2' fill='currentColor' fillOpacity='0.08' />
      <rect x='14.9854' y='42.4547' width='75.8413' height='17.6' rx='2' fill='currentColor' fillOpacity='0.08' />
      <rect x='14.9248' y='4.68896' width='74.1506' height='9.00999' rx='2' fill='currentColor' fillOpacity='0.08' />
      <rect x='20.0264' y='6.50403' width='6.00327' height='5.38019' rx='1' fill='currentColor' fillOpacity='0.3' />
      <rect x='33.877' y='7.96356' width='6.6372' height='2.46129' rx='1.23064' fill='currentColor' fillOpacity='0.3' />
      <rect
        x='48.3652'
        y='7.96356'
        width='6.6372'
        height='2.46129'
        rx='1.23064'
        fill='currentColor'
        fillOpacity='0.3'
      />
      <rect
        x='62.8506'
        y='7.96356'
        width='6.6372'
        height='2.46129'
        rx='1.23064'
        fill='currentColor'
        fillOpacity='0.3'
      />
      <rect
        x='77.3379'
        y='7.96356'
        width='6.6372'
        height='2.46129'
        rx='1.23064'
        fill='currentColor'
        fillOpacity='0.3'
      />
    </svg>
  )
}

export default LayoutHorizontal
