// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectGCP from '@components/platform/dialogs/connect-gcp'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const ArgoCDConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 128 128">
            <defs>
              <clipPath id="deviconArgocd0">
                <path d="M42 41h44v51H42zm0 0" />
              </clipPath>
              <clipPath id="deviconArgocd1">
                <use href="#deviconArgocdc" />
              </clipPath>
              <clipPath id="deviconArgocd2">
                <use href="#deviconArgocdc" />
              </clipPath>
              <clipPath id="deviconArgocd3">
                <path d="m85.695 41.133l-2.55 58.238H44.887l-2.125-58.238" />
              </clipPath>
              <clipPath id="deviconArgocd4">
                <use href="#deviconArgocdc" />
              </clipPath>
              <mask id="deviconArgocd5">
                <g filter="url(#deviconArgocdb)">
                  <use href="#deviconArgocdc" fill-opacity="0.251" />
                </g>
              </mask>
              <mask id="deviconArgocd6">
                <g filter="url(#deviconArgocdb)">
                  <use href="#deviconArgocdc" fill-opacity="0.22" />
                </g>
              </mask>
              <mask id="deviconArgocd7">
                <g filter="url(#deviconArgocdb)">
                  <use href="#deviconArgocdc" fill-opacity="0.502" />
                </g>
              </mask>
              <g id="deviconArgocd8" clip-path="url(#deviconArgocd4)">
                <path fill="#fbdfc3" d="M58.488 30.508a2.974 2.974 0 1 1-5.948-.003a2.974 2.974 0 0 1 5.948.003m0 0" />
              </g>
              <g id="deviconArgocd9" clip-path="url(#deviconArgocd2)">
                <path fill="#e34e3b" d="M84.422 65.363s2.55-22.531-.852-31.031C77.195 19.453 62.316 20.73 62.316 20.73s8.5 3.399 8.926 16.153c.426 8.926 0 22.105 0 22.105zm0 0" />
              </g>
              <g id="deviconArgocda" clip-path="url(#deviconArgocd1)">
                <path fill="#e9654b" d="M83.145 90.867V87.47c-5.95 3.398-12.329 6.8-19.977 6.8c-8.504 0-14.031-3.824-19.555-6.8l.422 3.398s6.38 6.805 19.555 6.805c12.328-.426 19.555-6.805 19.555-6.805zm0 0" />
              </g>
              <filter id="deviconArgocdb" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
                <feColorMatrix in="SourceGraphic" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
              </filter>
              <path id="deviconArgocdc" d="M0 0h128v128H0z" />
            </defs>
            <path fill="#e9654b" d="M44.035 89.594s-.847 2.55-2.125 3.824a3.84 3.84 0 0 1-2.972 1.277a50 50 0 0 1-6.38 1.274s2.977.426 6.38.851c1.273 0 1.273 0 2.124.426c2.126 0 2.973-1.277 2.973-1.277zm39.11 0s.851 2.55 2.125 3.824a3.86 3.86 0 0 0 2.976 1.277a50 50 0 0 0 6.375 1.274s-2.973.426-6.8.851c-1.274 0-1.274 0-2.126.426c-2.55 0-2.976-1.277-2.976-1.277zm0 0" />
            <path fill="#b6cfea" d="M109.926 47.508c0 25.355-20.555 45.91-45.91 45.91c-25.356 0-45.91-20.555-45.91-45.91c0-25.352 20.554-45.906 45.91-45.906s45.91 20.554 45.91 45.906m0 0" />
            <path fill="#e6f5f8" d="M108.227 47.508c0 24.418-19.793 44.21-44.211 44.21c-24.414 0-44.207-19.792-44.207-44.21C19.809 23.094 39.602 3.3 64.016 3.3c24.418 0 44.21 19.793 44.21 44.207zm0 0" />
            <path fill="#d0e8f0" d="M100.148 48.36c0 19.956-16.175 36.132-36.132 36.132c-19.954 0-36.133-16.176-36.133-36.133c0-19.953 16.18-36.132 36.133-36.132c19.957 0 36.132 16.18 36.132 36.132zm0 0" />
            <path fill="#ee794b" d="M42.762 65.363s2.976 48.035 2.976 48.887c0 .422.426 1.273-1.703 2.125c-2.125.848-8.926 2.55-8.926 2.55h10.203c4.676 0 4.676-3.827 4.676-4.675c0-.852 1.274-19.129 1.274-19.129s.425 21.68.425 22.527c0 .852-.425 2.125-3.398 2.977c-2.125.426-8.504 1.7-8.504 1.7h9.778c5.953 0 5.953-3.825 5.953-3.825l1.273-19.129s.426 19.129.426 21.254c0 1.7-1.274 2.977-5.953 3.824c-2.973.852-6.801 1.703-6.801 1.703h11.055c5.523-.425 6.375-4.254 6.375-4.254l9.351-47.609zm0 0" />
            <path fill="#ee794b" d="M85.27 65.363s-2.973 48.035-2.973 48.887c0 .422-.426 1.273 1.7 2.125c2.124.848 8.925 2.55 8.925 2.55H82.719c-4.676 0-4.676-3.827-4.676-4.675c0-.852-1.273-19.129-1.273-19.129s-.426 21.68-.426 22.527c0 .852.426 2.125 3.402 2.977l8.5 1.7H78.47c-5.95 0-5.95-3.825-5.95-3.825l-1.277-19.129s-.426 19.129-.426 21.254c0 1.7 1.278 2.977 5.954 3.824c2.976.852 6.8 1.703 6.8 1.703H72.52c-5.528-.425-6.38-4.254-6.38-4.254L56.79 74.29zm.425-23.379c0 11.903-9.777 21.254-21.254 21.254c-11.476 0-21.254-9.777-21.254-21.254c0-11.476 9.778-21.254 21.254-21.254s21.254 9.352 21.254 21.254m0 0" />
            <g clip-path="url(#deviconArgocd0)">
              <g clip-path="url(#deviconArgocd3)">
                <path fill="#ee794b" d="M102.273 53.46c0 20.895-16.937 37.833-37.832 37.833c-20.894 0-37.832-16.938-37.832-37.832s16.938-37.832 37.832-37.832s37.832 16.937 37.832 37.832zm0 0" />
              </g>
            </g>
            <use href="#deviconArgocd9" mask="url(#deviconArgocd6)" />
            <use href="#deviconArgocd8" mask="url(#deviconArgocd7)" />
            <path fill="#010101" d="M71.668 73.863c0 7.227-3.402 11.907-7.652 11.907s-7.653-5.528-7.653-12.754c0 0 3.403 6.8 8.078 6.8c4.676 0 7.227-5.953 7.227-5.953m0 0" />
            <path fill="#fff" d="M71.668 73.863c0 4.68-3.402 7.227-7.652 7.227s-7.227-3.399-7.227-7.649c0 0 3.402 4.25 8.078 4.25s6.801-3.828 6.801-3.828m0 0" />
            <path fill="#e9654b" d="M92.07 53.887c0 7.277-5.898 13.175-13.175 13.175s-13.18-5.898-13.18-13.175s5.902-13.18 13.18-13.18s13.175 5.902 13.175 13.18m-29.754 0c0 7.277-5.902 13.175-13.18 13.175s-13.175-5.898-13.175-13.175s5.898-13.18 13.176-13.18c7.277 0 13.18 5.902 13.18 13.18zm0 0" />
            <path fill="#fff" d="M89.098 53.887c0 5.633-4.57 10.203-10.203 10.203s-10.204-4.57-10.204-10.203c0-5.637 4.57-10.203 10.204-10.203c5.632 0 10.203 4.566 10.203 10.203m-30.61 0c0 5.633-4.566 10.203-10.199 10.203c-5.637 0-10.203-4.57-10.203-10.203a10.2 10.2 0 0 1 10.203-10.203c5.633 0 10.2 4.566 10.2 10.203zm0 0" />
            <path fill="#010101" d="M51.262 52.61a2.975 2.975 0 1 1-5.95.003a2.975 2.975 0 0 1 5.95-.004zm30.609 0a2.976 2.976 0 1 1-5.951.001a2.976 2.976 0 0 1 5.951-.002zm0 0" />
            <path fill="#b6cfea" d="M17.258 58.988a2.005 2.005 0 0 1-2.125-2.125V39.86a2 2 0 0 1 .582-1.543a2 2 0 0 1 1.543-.582a2.005 2.005 0 0 1 2.125 2.125v17.004c.035.57-.18 1.133-.586 1.54a2 2 0 0 1-1.54.585zm92.668 0a2 2 0 0 1-1.54-.586a2 2 0 0 1-.585-1.539V39.86a2 2 0 0 1 .586-1.543a2 2 0 0 1 1.539-.582a2 2 0 0 1 1.539.582c.41.407.62.97.586 1.543v17.004a2 2 0 0 1-.586 1.54a2 2 0 0 1-1.54.585zm0 0" />
            <path fill="#fff" d="M51.688 13.504a2.125 2.125 0 1 1-4.25 0a2.125 2.125 0 0 1 4.25 0M34.262 70.89a1.56 1.56 0 0 1-1.278-.425c-5.101-6.375-7.652-14.453-7.652-22.531a37.93 37.93 0 0 1 5.102-19.13a41.64 41.64 0 0 1 13.601-13.6a1.87 1.87 0 0 1 2.13.425a1.87 1.87 0 0 1-.427 2.125a34.3 34.3 0 0 0-17.43 29.754a33.5 33.5 0 0 0 7.227 20.832c.426.426.426 1.7-.426 2.125c-.425.426-.425.426-.847.426zm0 0" />
            <use href="#deviconArgocda" mask="url(#deviconArgocd5)" />
          </svg>
          <Typography variant='h5'>ArgoCD</Typography>
          <Typography color='text.primary'>
          Monitors running applications and compares the current, live state against the desired
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectGCP} />
        </CardContent>
      </Card>
    </>
  )
}

export default ArgoCDConnection
