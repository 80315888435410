// Next Imports
// import { redirect } from 'next/navigation'

import { useNavigate, useParams } from "react-router-dom";

// MUI Imports
import Grid from '@mui/material/Grid'

// Component Imports
import EditCard from '@views/apps/invoice/edit/EditCard'
import EditActions from '@views/apps/invoice/edit/EditActions'

import { db } from '@/app/api/fake-db/apps/invoice'

const getData = () => {
  // Vars
  // const res = await fetch(`${import.meta.env.VITE_REACT_APP_API_URL}/apps/invoice`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch invoice data')
  // }

  // return res.json()
  return db
}

const EditPage = ({ params }) => {
  let { id } = useParams(); 
  const navigate = useNavigate();
  
  // Vars
  const data = getData()
  const filteredData = data.filter(invoice => invoice.id === id)[0]

  if (!filteredData) {
    navigate('/not-found')
  }

  return filteredData ? (
    <Grid container spacing={6}>
      <Grid item xs={12} md={9}>
        <EditCard data={data} invoiceData={filteredData} id={id} />
      </Grid>
      <Grid item xs={12} md={3}>
        <EditActions id={id} />
      </Grid>
    </Grid>
  ) : null
}

export default EditPage
