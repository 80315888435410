// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectAzure from '@components/platform/dialogs/connect-azure'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const ThanosConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 200 107.314">
            <g data-name="Layer 2"><path d="M75.761 0v56.825h40.246a16.579 16.579 0 0 0 16.578-16.579V0zm44.293 35.82h2.121v2.121h-2.121zm-.667-9.131h3.446v3.447h-3.446zm-.53-9h4.507V22.2h-4.507zm-7.276 26.6h2.119v2.121h-2.121zm-.663-9.239h3.447v3.45h-3.447zm-.53-8.891h4.512v4.507h-4.507zm.53-4.492v-3.446h3.447v3.446zM94.644 44.289h2.121v2.121h-2.121zm-.663-9.131h3.447V38.6h-3.447zm.663-5.685v-2.121h2.121v2.121zm-.663-11.252h3.447v3.446h-3.447zm-7.806 26.068H88.3v2.121h-2.125zm0-8.469H88.3v2.121h-2.125zm-.662-9.131h3.446v3.447h-3.446zm-.531-9h4.508V22.2h-4.508zM83 7.241h42.342v8.469h-16.935v33.874h-8.468V15.71H83zM0 63.485h33.223v6.661H20.278v36.491h-7.333V70.146H0zM46.745 76.43h18.983v30.207H58.7V83.091H46.745v23.546h-7.027V63.485h7.027zM75.8 82.594V76.43h22.13v30.207H84.119c-6.721 0-10.972-3.82-10.972-9.618S77.709 87.4 84.119 87.4h6.906v-4.806zm15.228 18v-7.148H84c-2.715 0-4.5 1.109-4.5 3.573s1.787 3.574 4.5 3.574zm15.547-24.164h26.01v30.207h-7.027V83.091H113.6v23.546h-7.027zm48.718-.738A15.812 15.812 0 1 1 139.7 91.533a15.479 15.479 0 0 1 15.593-15.841zm0 25.273c4.869 0 8.382-3.946 8.382-9.432s-3.513-9.431-8.382-9.431c-4.934 0-8.448 3.945-8.448 9.431s3.514 9.432 8.448 9.432zm30.467-6.721c-5.667-1.416-9.185-4.312-9.185-9.552 0-5.612 4.688-9 11.037-9a14.869 14.869 0 0 1 11.279 5.055l-4.377 4.808a8.763 8.763 0 0 0-7.087-3.824c-2.59 0-4.131 1.3-4.131 2.836 0 1.48 1.3 2.469 2.961 2.835l4.743 1.11c5.18 1.234 9 4.131 9 9.431 0 5.24-4.066 9.371-12.016 9.371a15.272 15.272 0 0 1-12.579-5.857l4.437-4.558a10.48 10.48 0 0 0 8.694 4.377c3.639 0 4.683-1.606 4.683-3.087 0-1.29-.8-2.279-3.448-2.956z" fill="#6d41ff" data-name="Layer 1"/></g>
          </svg>
          <Typography variant='h5'>Thanos</Typography>
          <Typography color='text.primary'>
          Querying of your Prometheus metrics across multiple Prometheus servers and clusters
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectAzure} />
        </CardContent>
      </Card>
    </>
  )
}

export default ThanosConnection
