import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { history, fetchWrapper } from "../utils";

// create slice
const baseUrl = import.meta.env.VITE_REACT_APP_API_URL;
const name = "platform";

// APIs
export const connectgcp = createAsyncThunk(
  `${name}/connectgcp`,
  async ({ name, project_id, credentialsfile }) => {
    var formData = new FormData();
    formData.append("name", name);
    formData.append("project_id", project_id);
    formData.append("credentialsfile", credentialsfile);
    return await fetchWrapper.submit(`${baseUrl}/platform/connections`, formData)
  }
);

export const setupgkecluster = createAsyncThunk(
  `${name}/setupgkecluster`,
  async (data) => {
    const res = await fetchWrapper.post(`${baseUrl}/platform/setup-gke-cluster`, data);
    return res || res === "" ? res : null;
  }
);

function createInitialState() {
  return {
    connections: [],
    clusters: [],
    connectionStatus: "todo",
    clusterCreationStatus: "todo",
    error: null,
  }
}

// slice
export const platformSlice = createSlice({
  name: name,
  initialState: createInitialState(),
  reducers: {
    resetConnectionStatus: (state, action) => {
      return {
        ...state,
        connectionStatus: action.payload,
      };
    },
    resetClusterCreationStatus: (state, action) => {
      return {
        ...state,
        clusterCreationStatus: action.payload,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(connectgcp.fulfilled, (state, action) => {
        state.connections.push(action.payload)
        state.connectionStatus = "done"
        // history.navigate(from);
      })
      .addCase(setupgkecluster.fulfilled, (state, action) => {
        state.clusters.push(action.payload)
        state.clusterCreationStatus = "done"
        // history.navigate(from);
      });
  },
});

// Exports
export const { resetConnectionStatus, resetClusterCreationStatus } = platformSlice.actions;
export default platformSlice.reducer;
