// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectRancher from '@components/platform/dialogs/connect-rancher'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const DynatraceConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 256 260">
            <path fill="#1496ff" d="M86.386 21.69c-3.33 17.57-7.398 43.647-9.617 70.094c-3.884 46.606-1.48 77.862-1.48 77.862L9.633 231.973s-4.993-34.955-7.582-74.349C.57 133.212.016 111.758.016 98.812c0-.74.37-1.48.37-2.22c0-.924 1.11-9.617 9.617-17.754C19.251 69.96 87.495 16.51 86.386 21.69" />
            <path fill="#1284ea" d="M86.386 21.69c-3.33 17.57-7.398 43.647-9.617 70.094c0 0-72.684-8.693-76.753 8.877c0-.924 1.295-11.651 9.802-19.789c9.248-8.877 77.677-64.36 76.568-59.182" />
            <path fill="#b4dc00" d="M.016 96.408v4.068c.74-3.144 2.035-5.363 4.624-8.877c5.363-6.843 14.056-8.692 17.57-9.062c17.754-2.405 44.017-5.179 70.464-5.919c46.791-1.48 77.677 2.405 77.677 2.405l65.656-62.327s-34.4-6.473-73.609-11.097C136.691 2.455 114.128.791 101.366.051c-.924 0-9.987-1.11-18.494 7.028c-9.247 8.877-56.224 53.45-75.088 71.389c-8.508 8.137-7.768 17.2-7.768 17.94" />
            <path fill="#6f2da8" d="M233.602 176.119c-17.754 2.404-44.017 5.363-70.464 6.288c-46.791 1.48-77.862-2.404-77.862-2.404L19.62 242.514s34.77 6.843 73.979 11.282c24.043 2.774 45.311 4.254 58.257 4.994c.925 0 2.405-.74 3.33-.74c.924 0 9.987-1.665 18.494-9.802c9.247-8.878 65.1-72.684 59.922-72.129" />
            <path fill="#591f91" d="M233.602 176.119c-17.754 2.404-44.017 5.363-70.464 6.288c0 0 4.994 73.054-12.576 76.198c.925 0 12.946-.555 21.454-8.693c9.247-8.877 66.765-74.348 61.586-73.793" />
            <path fill="#73be28" d="M154.446 258.975c-1.295 0-2.59-.185-4.07-.185c3.33-.555 5.55-1.665 9.063-4.254c7.028-4.994 9.248-13.686 9.987-17.2c3.144-17.57 7.398-43.647 9.433-70.094c3.698-46.607 1.48-77.677 1.48-77.677l65.655-62.512s4.808 34.77 7.582 74.163c1.665 25.708 2.22 48.456 2.405 61.032c0 .925.74 9.987-7.768 18.125c-9.247 8.877-56.223 53.634-74.903 71.574c-8.877 8.137-17.94 7.028-18.864 7.028" />
          </svg>
          <Typography variant='h5'>Dynatrace</Typography>
          <Typography color='text.primary'>
          Drive business transformation with contextual analytics, AI, and automation.
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectRancher} />
        </CardContent>
      </Card>
    </>
  )
}

export default DynatraceConnection
