// MUI Imports
import Card from '@mui/material/Card'

// Component Imports
import CalendarWrapper from '@views/apps/calendar/CalendarWrapper'

// Styled Component Imports
import AppFullCalendar from '@/libs/styles/AppFullCalendar'

import { events } from '@/app/api/fake-db/calendar'

async function fetchEvents() {
  // Vars
  // const res = await fetch(`${import.meta.env.VITE_REACT_APP_API_URL}/apps/calendar-events`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch data')
  // }

  // return res.json()
  return events
}

const CalendarApp = () => {
  // Vars
  const res = (fetchEvents()) || []

  return (
    <Card className='overflow-visible'>
      <AppFullCalendar className='app-calendar'>
        <CalendarWrapper events={res.events} />
      </AppFullCalendar>
    </Card>
  )
}

export default CalendarApp
