// React Imports
import { useState } from 'react'
import React, { useEffect } from "react";

// Next Imports
import Link from '@mui/material/Link';
import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// MUI Imports
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'

// Third-party Imports
// import { signIn } from 'next-auth/react'
import { Controller, useForm } from 'react-hook-form'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { object, minLength, string, email } from 'valibot'

// Component Imports
import Logo from '@core/svg/Logo'
import Illustrations from '@components/Illustrations'

// Config Imports
import themeConfig from '@configs/themeConfig'

// Hook Imports
import { useImageVariant } from '@core/hooks/useImageVariant'

// Util Imports
import { getLocalizedUrl } from '@/utils/i18n'

import { login, loginWithToken } from "@/redux-store/auth.slice";

import OpsEngineLogo from '../assets/images/OpsEngineLogo.png'

const schema = object({
  email: string([minLength(1, 'This field is required'), email('Email is invalid')]),
  password: string([
    minLength(1, 'This field is required'),
    minLength(5, 'Password must be at least 5 characters long')
  ])
})

const Login = ({ mode }) => {
  // States
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [errorState, setErrorState] = useState(null)

  // Vars
  const darkImg = '/images/pages/auth-v2-mask-dark.png'
  const lightImg = '/images/pages/auth-v2-mask-light.png'
  const darkIllustration = '/images/illustrations/auth/v2-login-dark.png'
  // const lightIllustration = '/images/illustrations/auth/v2-login-light.png'
  const lightIllustration = '/images/illustrations/auth/e1.png'
  const borderedDarkIllustration = '/images/illustrations/auth/v2-login-dark-border.png'
  const borderedLightIllustration = '/images/illustrations/auth/v2-login-light-border.png'

  // Hooks
  const { lang: locale } = useParams()

  const dispatch = useDispatch();
  const authUser = useSelector((x) => x?.auth?.user);
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const loginStatus = searchParams.get("action") ?? "";
  const loginToken = searchParams.get("token") ?? "";

  useEffect(() => {
    if (loginStatus === 'GoogleAuthenticationSuccessful' && loginToken) {
        return dispatch(loginWithToken(loginToken))
    }
    // redirect to Dashboard if already logged in
    else if (authUser) {
        navigate('/en/dashboards/summary');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: valibotResolver(schema),
    defaultValues: {
      email: 'email@example.com',
      password: 'yourpassword'
    }
  })

  const authBackground = useImageVariant(mode, lightImg, darkImg)

  const characterIllustration = useImageVariant(
    mode,
    lightIllustration,
    darkIllustration,
    borderedLightIllustration,
    borderedDarkIllustration
  )

  const handleClickShowPassword = () => setIsPasswordShown(show => !show)

  const onSubmit = ({ email, password }) => {
    return dispatch(login({ email, password }));
  }

  return (
    <div className='flex bs-full justify-center'>
      {/* <div className='flex justify-center items-center bs-full bg-backgroundPaper !min-is-full p-6 md:!min-is-[unset] md:p-12 md:is-[480px]'> */}
      <div className='flex justify-center items-center bs-full bg-backgroundPaper w-1/2'>
        <div className='flex justify-center items-center bs-full bg-backgroundPaper !min-is-full p-6 md:!min-is-[unset] md:p-12 md:is-[480px]'>
          <div className='absolute block-start-5 sm:block-start-[33px] inline-start-6 sm:inline-start-[38px]'>
            <div className='flex justify-center items-center gap-3 mbe-6'>
              {/* <Logo className='text-primary' height={28} width={35} /> */}
              <img src={OpsEngineLogo} alt='auth img' width='64px' height='64px' />
              <Typography variant='h4' className='font-semibold tracking-[0.15px]'>
                {themeConfig.templateName}
              </Typography>
            </div>
          </div>
          <div className='flex flex-col gap-5 is-full sm:is-auto md:is-full sm:max-is-[400px] md:max-is-[unset]'>
            <div>
              {/* <Typography variant='h4'>{`Welcome to ${themeConfig.templateName}!👋🏻`}</Typography> */}
              <Typography variant='h4'>{`Sign in`}</Typography>
              <Typography>and get started with the adventure</Typography>
            </div>
            {/* <Alert icon={false} className='bg-[var(--mui-palette-primary-lightOpacity)]'>
              <Typography variant='body2' color='primary'>
                Email: <span className='font-medium'>admin@opsengine.ai</span> / Pass:{' '}
                <span className='font-medium'>admin</span>
              </Typography>
            </Alert> */}

            <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-5'>
              <Controller
                name='email'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    autoFocus
                    type='email'
                    label='Email'
                    onChange={e => {
                      field.onChange(e.target.value)
                      errorState !== null && setErrorState(null)
                    }}
                    {...((errors.email || errorState !== null) && {
                      error: true,
                      helperText: errors?.email?.message || errorState?.message[0]
                    })}
                  />
                )}
              />
              <Controller
                name='password'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='Password'
                    id='login-password'
                    type={isPasswordShown ? 'text' : 'password'}
                    onChange={e => {
                      field.onChange(e.target.value)
                      errorState !== null && setErrorState(null)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onClick={handleClickShowPassword}
                            onMouseDown={e => e.preventDefault()}
                            aria-label='toggle password visibility'
                          >
                            <i className={isPasswordShown ? 'ri-eye-off-line' : 'ri-eye-line'} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...(errors.password && { error: true, helperText: errors.password.message })}
                  />
                )}
              />
              <div className='flex justify-between items-center flex-wrap gap-x-3 gap-y-1'>
                <FormControlLabel control={<Checkbox defaultChecked />} label='Remember me' />
                <Typography className='text-end' color='primary' component={Link} href='/forgot-password'>
                  Forgot password?
                </Typography>
              </div>
              <Button fullWidth variant='contained' type='submit'>
                Log In
              </Button>
              <div className='flex justify-center items-center flex-wrap gap-2'>
                <Typography>New on our platform?</Typography>
                <Typography component={Link} href='/en/register' color='primary'>
                  Create an account
                </Typography>
              </div>
            </form>
            <Divider className='gap-3'>or</Divider>
            <Button
              disabled
              color='secondary'
              className='self-center text-textPrimary'
              startIcon={<img src='/images/logos/google.png' alt='Google' width={22} />}
              sx={{ '& .MuiButton-startIcon': { marginInlineEnd: 3 } }}
              onClick={() => signIn('google')}
            >
              Sign in with Google
            </Button>
          </div>
        </div>
      </div>
      {/* <div className='flex bs-full items-center justify-center flex-1 min-bs-[100dvh] relative p-6 max-md:hidden'> */}
      <div className='flex bs-full items-center flex-1 min-bs-[100dvh] relative pl-10 w-1/2 max-md:hidden'>
        {/* <div className='plb-12 pis-12'>
          <img
            src={characterIllustration}
            alt='character-illustration'
            className='max-bs-[1000px] max-is-full bs-auto'
          />
        </div> */}
        <img
            src={characterIllustration}
            alt='character-illustration'
            className='max-bs-[1000px] max-is-full bs-auto'
          />
        {/* <Illustrations
          image1={{ src: '/images/illustrations/objects/tree-2.png' }}
          image2={null}
          maskImg={{ src: authBackground }}
        /> */}
      </div>
      
    </div>
  )
}

export default Login
