// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectRancher from '@components/platform/dialogs/connect-rancher'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const LokiConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg width="48" height="56" viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0478 54.9248L11.3838 50.4663L6.92529 51.1304L7.68418 55.5889L12.0478 54.9248Z" fill="url(#paint0_linear_17931_893)" />
            <path d="M46.957 42.4032L46.1981 38.0396L26.7515 41.0751L27.3206 45.4388L46.957 42.4032Z" fill="url(#paint1_linear_17931_893)" />
            <path d="M20.395 46.5772L24.8535 45.8183L24.1895 41.4546L19.731 42.1186L20.395 46.5772Z" fill="url(#paint2_linear_17931_893)" />
            <path d="M19.0674 53.7865L18.3085 49.4229L13.9448 50.0869L14.514 54.5454L19.0674 53.7865Z" fill="url(#paint3_linear_17931_893)" />
            <path d="M5.88135 44.2055L6.54539 48.6641L11.0039 48L10.3399 43.5415L5.88135 44.2055Z" fill="url(#paint4_linear_17931_893)" />
            <path d="M27.6997 47.9051L28.4586 52.4585L48.0001 49.4229L47.3361 44.9644L27.6997 47.9051Z" fill="url(#paint5_linear_17931_893)" />
            <path d="M21.5333 53.407L25.8969 52.8378L25.2329 48.2844L20.7744 49.0433L21.5333 53.407Z" fill="url(#paint6_linear_17931_893)" />
            <path d="M12.8062 43.1621L13.565 47.6205L17.9287 46.9566L17.2646 42.498L12.8062 43.1621Z" fill="url(#paint7_linear_17931_893)" />
            <path d="M7.39921 41.4546L1.99207 5.97632L0 6.26089L5.50197 41.7392L7.39921 41.4546Z" fill="url(#paint8_linear_17931_893)" />
            <path d="M9.96032 41.0751L4.07888 2.94067L2.18164 3.32014L8.06308 41.3597L9.96032 41.0751Z" fill="url(#paint9_linear_17931_893)" />
            <path d="M14.3245 40.4111L8.15847 0L6.26123 0.379412L12.4272 40.6008L14.3245 40.4111Z" fill="url(#paint10_linear_17931_893)" />
            <path d="M16.8852 40.0315L11.1935 3.2251L9.39111 3.50967L15.0828 40.2212L16.8852 40.0315Z" fill="url(#paint11_linear_17931_893)" />
            <path d="M21.2491 39.2728L16.2215 6.64038L14.3242 6.92495L19.3519 39.6523L21.2491 39.2728Z" fill="url(#paint12_linear_17931_893)" />
            <path d="M23.8104 38.8935L18.593 5.02783L16.6958 5.31241L22.0081 39.1781L23.8104 38.8935Z" fill="url(#paint13_linear_17931_893)" />
            <defs>
              <linearGradient id="paint0_linear_17931_893" x1="11.6469" y1="66.8772" x2="1.23198" y2="-0.802501" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint1_linear_17931_893" x1="39.9916" y1="62.5154" x2="29.5768" y2="-5.1639" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint2_linear_17931_893" x1="25.5063" y1="64.7445" x2="15.0913" y2="-2.93516" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint3_linear_17931_893" x1="18.5788" y1="65.8105" x2="8.1638" y2="-1.86922" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint4_linear_17931_893" x1="11.6394" y1="66.8784" x2="1.22448" y2="-0.80128" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint5_linear_17931_893" x1="39.9982" y1="62.5143" x2="29.5833" y2="-5.16528" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint6_linear_17931_893" x1="25.506" y1="64.7443" x2="15.091" y2="-2.93537" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint7_linear_17931_893" x1="18.5788" y1="65.8103" x2="8.16407" y2="-1.86867" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint8_linear_17931_893" x1="10.1623" y1="65.7597" x2="0.284696" y2="1.57166" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint9_linear_17931_893" x1="13.0129" y1="67.1431" x2="2.40785" y2="-1.77243" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint10_linear_17931_893" x1="17.6338" y1="68.0331" x2="6.38943" y2="-5.0367" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint11_linear_17931_893" x1="19.8305" y1="65.208" x2="9.57925" y2="-1.40832" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint12_linear_17931_893" x1="23.7353" y1="61.7393" x2="14.6289" y2="2.56246" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
              <linearGradient id="paint13_linear_17931_893" x1="26.4465" y1="62.1967" x2="16.9911" y2="0.751851" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FAED1E" />
                <stop offset="1" stop-color="#F15B2B" />
              </linearGradient>
            </defs>
          </svg>
          <Typography variant='h5'>Grafana Loki</Typography>
          <Typography color='text.primary'>
            Use this modal to modify the existing user&#39;s current information.
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectRancher} />
        </CardContent>
      </Card>
    </>
  )
}

export default LokiConnection
