// React Imports
import { useEffect, useState } from 'react'
import CodeEditor from '@uiw/react-textarea-code-editor';

// MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

// Third-party Imports
import classnames from 'classnames'

// Component Imports
import CustomInputVertical from '@core/components/custom-inputs/Vertical'
import DirectionalIcon from '@components/DirectionalIcon'
import { Divider } from '@mui/material'

const generatedCode = `
pipeline {
  agent any

  environment {
      NODE_ENV = 'production'
  }

  stages {
      stage('Checkout') {
          steps {
              // Checkout the code from the source repository
              git url: 'https://github.com/your-repo/your-nodejs-app.git', branch: 'main'
          }
      }

      stage('Install Dependencies') {
          steps {
              // Install Node.js dependencies
              script {
                  if (fileExists('package-lock.json')) {
                      sh 'npm ci'
                  } else {
                      sh 'npm install'
                  }
              }
          }
      }

      stage('Lint') {
          steps {
              // Run linting to check for code quality
              sh 'npm run lint'
          }
      }

      stage('Test') {
          steps {
              // Run tests
              sh 'npm test'
          }
      }

      stage('Build') {
          steps {
              // Build the application (if applicable)
              sh 'npm run build'
          }
      }

      stage('Deploy') {
          when {
              branch 'main'
          }
          steps {
              // Deploy the application
              sh 'npm run deploy'
          }
      }
  }

  post {
      always {
          // Clean up workspace after the build
          cleanWs()
      }
      success {
          // Notify on success
          echo 'Build succeeded!'
      }
      failure {
          // Notify on failure
          echo 'Build failed!'
      }
  }
}

`;
// Vars
const data = [
  {
    title: 'Platform Blueprint',
    value: 'builder',
    content: 'Generate platform blueprint for provisioning infrastructure.',
    asset: 'ri-home-6-line',
    isSelected: true
  },
  {
    title: 'DevOps Blueprint',
    value: 'owner',
    content: 'Generate DevOps blueprint for deploying applications.',
    asset: 'ri-user-3-line'
  },
  // {
  //   title: 'I am the broker',
  //   value: 'broker',
  //   content: 'Earn highest commission by listing your clients properties at the best price.',
  //   asset: 'ri-money-dollar-circle-line'
  // }
]

import Card from '@mui/material/Card'
// import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

const StepBlueprintDetails = ({ activeStep, handleNext, handlePrev, steps }) => {
  const [code, setCode] = useState('')
  // Vars
  const initialSelectedOption = data.filter(item => item.isSelected)[data.filter(item => item.isSelected).length - 1]
    .value

  // States
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption)
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const handleClickShowPassword = () => setIsPasswordShown(show => !show)

  const handleOptionChange = prop => {
    if (typeof prop === 'string') {
      setSelectedOption(prop)
    } else {
      setSelectedOption(prop.target.value)
    }
  }

  const handleGenerate = () => {
    alert('Generating Blueprint..!!')
    
    setCode(generatedCode);
  }

  return (
    <>
      <Grid container spacing={5}>
        
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent className='flex flex-col items-center text-center gap-4'>
              {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="10em" height="10em" viewBox="0 0 24 24">
                <path fill="currentColor" d="M21 11V9h-2V7a2.006 2.006 0 0 0-2-2h-2V3h-2v2h-2V3H9v2H7a2.006 2.006 0 0 0-2 2v2H3v2h2v2H3v2h2v2a2.006 2.006 0 0 0 2 2h2v2h2v-2h2v2h2v-2h2a2.006 2.006 0 0 0 2-2v-2h2v-2h-2v-2Zm-4 6H7V7h10Z" />
                <path fill="currentColor" d="M11.361 8h-1.345l-2.01 8h1.027l.464-1.875h2.316L12.265 16h1.062Zm-1.729 5.324L10.65 8.95h.046l.983 4.374ZM14.244 8h1v8h-1z" />
              </svg>
              {/* <Typography variant='h5'>AI Engine</Typography> */}
              {/* <Typography color='text.primary'>
              Build, Deploy, and Manage Websites, Apps or Processes On AWS Secure, Reliable Network
              </Typography> */}
              {/* <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectAWS} /> */}
            </CardContent>
          </Card>
        </Grid>

        {data.map((item, index) => {
          let asset

          if (item.asset && typeof item.asset === 'string') {
            asset = <i className={classnames(item.asset, 'text-[28px]')} />
          }

          return (
            <CustomInputVertical
              type='radio'
              key={index}
              gridProps={{ sm: 4, xs: 12 }}
              selected={selectedOption}
              name='custom-radios-basic'
              handleChange={handleOptionChange}
              data={typeof item.asset === 'string' ? { ...item, asset } : item}
            />
          )
        })}
        
        <Grid item xs={12} md={12}>
          <TextField fullWidth label='Name of the blueprint' placeholder='My Generated Blueprint' />
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <TextField fullWidth label='Prompt' placeholder='Generate a jenkins pipeline to build a nodejs application' /> */}
          <TextField
            fullWidth
            rows={4}
            multiline
            variant='filled'
            label='Prompt'
            id='textarea-filled-static'
            defaultValue='Generate a jenkins pipeline to build a nodejs application'
          />
        </Grid>
        
        <Grid item xs={12}>
          <div className='flex items-center justify-between'>
            {/* <Button
              variant='outlined'
              color='secondary'
              disabled={activeStep === 0}
              onClick={handlePrev}
              startIcon={<DirectionalIcon ltrIconClass='ri-arrow-left-line' rtlIconClass='ri-arrow-right-line' />}
            >
              Previous
            </Button> */}
            <Button
              variant='contained'
              color={activeStep === steps.length - 1 ? 'success' : 'primary'}
              // onClick={handleNext}
              onClick={handleGenerate}
              endIcon={
                activeStep === steps.length - 1 ? (
                  <i className='ri-check-line' />
                ) : (
                  <DirectionalIcon ltrIconClass='ri-arrow-right-line' rtlIconClass='ri-arrow-left-line' />
                )
              }
            >
              {activeStep === steps.length - 1 ? 'Generate' : 'Next'}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider className='mlb-6' />
      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <CodeEditor
            value={code}
            language="js"
            placeholder="Generated blueprint code goes here."
            onChange={(evn) => setCode(evn.target.value)}
            padding={15}
            style={{
              backgroundColor: "#f5f5f5",
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default StepBlueprintDetails

// React Imports
// import { useState } from 'react'

// MUI Imports
// import Grid from '@mui/material/Grid'
// import TextField from '@mui/material/TextField'

const TextareaVariant = () => {
  // States
  const [value, setValue] = useState('Controlled')

  const handleChange = event => {
    setValue(event.target.value)
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          value={value}
          label='Multiline'
          onChange={handleChange}
          id='textarea-outlined-controlled'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField fullWidth multiline id='textarea-outlined' placeholder='Placeholder' label='Multiline Placeholder' />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          rows={4}
          multiline
          label='Multiline'
          defaultValue='Default Value'
          id='textarea-outlined-static'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          value={value}
          variant='filled'
          label='Multiline'
          onChange={handleChange}
          id='textarea-filled-controlled'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          multiline
          variant='filled'
          id='textarea-filled'
          placeholder='Placeholder'
          label='Multiline Placeholder'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          rows={4}
          multiline
          variant='filled'
          label='Multiline'
          id='textarea-filled-static'
          defaultValue='Default Value'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          value={value}
          label='Multiline'
          variant='standard'
          onChange={handleChange}
          id='textarea-standard-controlled'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          multiline
          variant='standard'
          id='textarea-standard'
          placeholder='Placeholder'
          label='Multiline Placeholder'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          rows={4}
          multiline
          label='Multiline'
          variant='standard'
          defaultValue='Default Value'
          id='textarea-standard-static'
        />
      </Grid>
    </Grid>
  )
}
