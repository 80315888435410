// Component Imports
import Pricing from '@views/pages/pricing'

import { db } from '@/app/api/fake-db/pages/pricing'

const getPricingData = () => {
  // Vars
  // const res = await fetch(`${import.meta.env.VITE_REACT_APP_API_URL}/pages/pricing`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch data')
  // }

  // return res.json()
  return db
}

const PricePage = () => {
  // Vars
  const data = getPricingData()

  return <Pricing data={data} />
}

export default PricePage
