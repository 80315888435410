// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectAWS from '@components/platform/dialogs/connect-aws'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const ElasticConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 32 32">
            <path fill="#fed10a" d="m12.761 13.89l6.644 3.027l6.7-5.874a7 7 0 0 0 .141-1.475a7.484 7.484 0 0 0-13.66-4.233l-1.12 5.785Z" />
            <path fill="#24bbb1" d="M5.886 20.919a7.3 7.3 0 0 0-.141 1.5a7.514 7.514 0 0 0 13.724 4.22l1.1-5.759l-1.469-2.821l-6.67-3.04Z" />
            <path fill="#ef5098" d="M5.848 9.426L10.4 10.5l1-5.169a3.594 3.594 0 0 0-5.552 4.095" />
            <path fill="#17a8e0" d="M5.45 10.517a5.016 5.016 0 0 0-.218 9.453l6.388-5.77l-1.167-2.5Z" />
            <path fill="#93c83e" d="M20.624 26.639a3.589 3.589 0 0 0 5.541-4.092l-4.541-1.065Z" />
            <path fill="#0779a1" d="m21.547 20.29l5 1.167A5.016 5.016 0 0 0 26.768 12l-6.541 5.733Z" />
          </svg>
          <Typography variant='h5'>Elastic</Typography>
          <Typography color='text.primary'>
          Elasticsearch indexes, analyzes, and searches the ingested data
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectAWS} />
        </CardContent>
      </Card>
    </>
  )
}

export default ElasticConnection
