import { createSlice } from "@reduxjs/toolkit";

const appConfigRaw = localStorage.getItem("appConfig");
const appConfig =
  appConfigRaw && appConfigRaw !== "undefined" ? JSON.parse(appConfigRaw) : {};

// create slice
export const appConfigSlice = createSlice({
  name: "appConfig",
  initialState: {
    sideBarPinned: appConfig?.sideBarPinned || false,
  },

  reducers: {
    updateSidebarBarPinStatus: (state, action) => {
      const appConfiRaw = localStorage.getItem("appConfig");
      let appConfig =
        appConfiRaw && appConfiRaw !== "undefined"
          ? JSON.parse(appConfiRaw)
          : { sideBarPinned: false };
      appConfig.sideBarPinned = action.payload;
      localStorage.setItem("appConfig", JSON.stringify(appConfig));
      return {
        ...state,
        sideBarPinned: action.payload,
      };
    },
  },
});

export const { updateSidebarBarPinStatus, addLocalTableConfig } =
  appConfigSlice.actions;

export default appConfigSlice.reducer;
