'use server'

// Next Imports
// import { cookies, headers } from 'next/headers'

// Config Imports
import themeConfig from '@configs/themeConfig'
import demoConfigs from '@configs/demoConfigs'

export const getDemoName = () => {
  // const headersList = headers()

  // return headersList.get('X-server-header')
  return "OpsEngine"
}

export const getSettingsFromCookie = () => {
  // const cookieStore = cookies()
  // const demoName = getDemoName()

  // const cookieName = demoName
  //   ? themeConfig.settingsCookieName.replace('demo-1', demoName)
  //   : themeConfig.settingsCookieName

  // return JSON.parse(cookieStore.get(cookieName)?.value || '{}')
  return {}
}

export const getMode = () => {
  // const settingsCookie = getSettingsFromCookie()
  // const demoName = getDemoName()

  // // Get mode from cookie or fallback to theme config
  // const _mode = settingsCookie.mode || (demoName && demoConfigs[demoName].mode) || themeConfig.mode

  // return _mode
  return "light"
}

export const getSystemMode = () => {
  // const cookieStore = cookies()
  // const mode = getMode()
  // const colorPrefCookie = cookieStore.get('colorPref')?.value || 'light'

  // return (mode === 'system' ? colorPrefCookie : mode) || 'light'
  return "light"
}

export const getServerMode = () => {
  const mode = getMode()
  const systemMode = getSystemMode()

  return mode === 'system' ? systemMode : mode
}

export const getSkin = () => {
  // const settingsCookie = getSettingsFromCookie()

  // return settingsCookie.skin || 'default'
  return 'default'
}
