// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectTanzu from '@components/platform/dialogs/connect-tanzu'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const FluentdConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M1.83 2.195C1.279 2.191.8 2.377.425 2.82C-.429 3.823.03 6.135 1.787 8.917l.02.028a10 10 0 0 0 .197.297c1.733 2.579 4.249 4.677 3.743 7.586a4.3 4.3 0 0 1-.599 1.564C3.615 20.797.003 21.047.003 21.047l.042.001l-.045.005s4.355 1.344 8.533.438c.598-.13 1.192-.305 1.769-.537l.023-.01q.514-.207.975-.437a12 12 0 0 0 .282-.145q.427-.225.814-.467l.152-.095q.473-.306.888-.635l.146-.116q.246-.201.476-.407l.123-.105l.036-.04q.011-.012.024-.023a13 13 0 0 0 .86-.888q.076-.084.148-.169l.175-.203l.1-.123q.19-.232.372-.465l.032-.042c.679-.88 1.28-1.779 1.933-2.639l.216-.278c.294-.374.6-.74.932-1.091l.03-.031q.162-.17.334-.337l.07-.067a11 11 0 0 1 .264-.243q.054-.049.109-.096a5 5 0 0 1 .374-.293l.038-.027a5 5 0 0 1 .85-.48l-.065.03l.022-.01l.06-.027a5.6 5.6 0 0 1 1.63-.412h.001a6 6 0 0 1 .533-.026h.07l.115.002l.428-.003a.2.2 0 0 0 .088-.016c.062-.03.047-.102-.012-.186a1.4 1.4 0 0 0-.39-.343l-.098-.052l-.036-.022a4 4 0 0 1-.256-.161l-.129-.096a4 4 0 0 1-.193-.15c-.205-.2-.432-.733-.523-.888a2 2 0 0 0-.24-.33c-.272-.306-.665-.533-1.302-.615c-.891-.115-1.727.072-2.565.333l.1-.035c-1.168.354-2.334.87-3.655.94h-.04a6 6 0 0 1-.668-.01l-.06-.004a5.5 5.5 0 0 1-1.16-.233c-1.312-.412-3.154-1.88-5.047-3.316l-.651-.49a52 52 0 0 0-.789-.58l-.222-.155l-.236-.166l-.086-.06a18 18 0 0 0-1.612-.997l-.019-.01a10 10 0 0 0-.367-.186l-.117-.053a8 8 0 0 0-.548-.23l-.05-.017a5 5 0 0 0-.316-.101l-.026-.007a3 3 0 0 0-.816-.125zM20.6 8.641a.504.5 0 0 1 .505.5a.504.5 0 0 1-.505.5a.504.5 0 0 1-.504-.5a.504.5 0 0 1 .504-.5" />
          </svg>
          <Typography variant='h5'>Fluentd</Typography>
          <Typography color='text.primary'>
          Open source data collector, which lets you unify the data collection
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectTanzu} />
        </CardContent>
      </Card>
    </>
  )
}

export default FluentdConnection
