// Next Imports
// import { redirect } from 'next/navigation'

import { useNavigate, useParams } from "react-router-dom";

// Component Imports
import Preview from '@views/apps/invoice/preview'

import { db } from '@/app/api/fake-db/apps/invoice'

const getData = () => {
  // Vars
  // const res = await fetch(`${import.meta.env.VITE_REACT_APP_API_URL}/apps/invoice`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch invoice data')
  // }

  // return res.json()
  return db
}

const PreviewPage = ({ params }) => {
  let { id } = useParams(); 
  const navigate = useNavigate();
  // Vars
  const data = getData()
  const filteredData = data.filter(invoice => invoice.id === id)[0]

  if (!filteredData) {
    navigate('/not-found')
  }

  return filteredData ? <Preview invoiceData={filteredData} id={id} /> : null
}

export default PreviewPage
