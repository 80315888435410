import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { history, fetchWrapper } from "../utils";

// create slice
const baseUrl = import.meta.env.VITE_REACT_APP_API_URL;
const name = "auth";

// APIs
export const login = createAsyncThunk(
  `${name}/login`,
  async ({ email, password }) =>
    await fetchWrapper.post(`${baseUrl}/auth/login`, { email, password })
);

export const register = createAsyncThunk(
  `${name}/register`,
  async ({ accountName, region, cloudProvider, email, password }) =>
    await fetchWrapper.post(`${baseUrl}/auth/register`, {
      name: accountName,
      email,
      password,
      region, cloudProvider
    })
);

export const verifyEmail = createAsyncThunk(
  `${name}/verifyEmail`,
  async ({ otp, token }) =>
    await fetchWrapper.post(`${baseUrl}/auth/verify-email`, {
      token,
      otp,
    })
);

export const sendVerificationEmail = createAsyncThunk(
  `${name}/verifyEmail`,
  async ({ token }) =>
    await fetchWrapper.post(`${baseUrl}/auth/send-verification-email`, {
      token,
    })
);

export const getRefreshToken = createAsyncThunk(
  `${name}/getRefreshToken`,
  async (token) =>
    await fetchWrapper.post(`${baseUrl}/user/refreshJwt`, { refreshJwt: token })
);

function createInitialState() {
  const userToken = localStorage.getItem("userToken");
  let userData = null;
  if (userToken) {
    userData = JSON.parse(
      atob(localStorage.getItem("userToken")?.split(".")[1])
    );
  }
  return {
    // initialize state from local storage to enable user to stay logged in
    user: userData, //JSON.parse(localStorage.getItem('userToken')),
    error: null,
  };
}

// slice
export const authSlice = createSlice({
  name: name,
  initialState: createInitialState(),
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem("userToken");
      history.navigate("/en/login");
      return {
        ...state,
        user: null,
        error: null,
      };
    },
    refreshToken: (state, action) => {
      const user = action.payload;
      localStorage.setItem("userToken", user);
      state.user = JSON.parse(atob(user.split(".")[1]));
    },
    setLocalUser: (state, action) => {
      const userData = { ...state.user };
      return {
        ...state,
        user: { ...userData, ...action.payload },
      };
    },
    loginWithToken: (state, action) => {
      localStorage.setItem("userToken", action.payload);
      state.user = JSON.parse(atob(action.payload.split(".")[1]));
      const { from } = history.location.state || { from: { pathname: "/" } };
      history.navigate(from);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.status = "loading";
      })
      .addCase(register.fulfilled, (state, action) => {
        const token = action.payload.tokens.access.token;
        const email = action.payload.user.email;
        console.log("EMEIDEWW", email, token);
        history.navigate("/email-verification", { state: { token, email } });
      })
      .addCase(login.fulfilled, (state, action) => {
        const token = action.payload.tokens.access.token;
        const user = action.payload.user;
        console.log('user', action)
        localStorage.setItem("userToken", token);
        state.user = user;
        console.log(user, "USER ON STATE")

        // get return url from location state or default to Dashboard page
        const { from } = history?.location?.state || { from: { pathname: "/en/dashboards/summary" } };
        history.navigate(from);
      })
      .addCase(login.pending, (state, action) => {
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(getRefreshToken.fulfilled, (state, action) => {
        const user = action.payload.accessToken;
        localStorage.setItem("userToken", user);
        state.user = JSON.parse(atob(user.split(".")[1]));
      });
  },
});

// Exports
export const { logout, refreshToken, loginWithToken, setLocalUser } =
  authSlice.actions;
export default authSlice.reducer;
