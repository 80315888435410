// Next Imports
import { lazy as dynamic } from 'react';

// Component Imports
import UserProfile from '@views/pages/user-profile'

const ProfileTab = dynamic(() => import('@views/pages/user-profile/profile/index'))
const TeamsTab = dynamic(() => import('@views/pages/user-profile/teams/index'))
const ProjectsTab = dynamic(() => import('@views/pages/user-profile/projects/index'))
const ConnectionsTab = dynamic(() => import('@views/pages/user-profile/connections/index'))

import { db } from '@/app/api/fake-db/pages/user-profile'

// Vars
const tabContentList = data => ({
  profile: <ProfileTab data={data?.users.profile} />,
  teams: <TeamsTab data={data?.users.teams} />,
  projects: <ProjectsTab data={data?.users.projects} />,
  connections: <ConnectionsTab data={data?.users.connections} />
})

const getData = () => {
  // Vars
  // const res = await fetch(`${import.meta.env.VITE_REACT_APP_API_URL}/pages/profile`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch profileData')
  // }

  // return res.json()
  return db
}

const ProfilePage = () => {
  // Vars
  const data = getData()

  return <UserProfile data={data} tabContentList={tabContentList(data)} />
}

export default ProfilePage
