// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectRancher from '@components/platform/dialogs/connect-rancher'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const RancherConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="5em" height="5em" viewBox="0 0 128 128">
            <path d="M0 75.734h9.049c4.27 0 7.116 2.033 7.116 6.1c0 3.253-2.237 5.287-4.372 5.897c.61.508 1.119 1.321 1.525 2.033c.915 1.83 1.525 3.863 3.457 3.863c.509 0 .915-.203.915-.203l-.508 3.965s-1.118.305-2.135.305c-2.542 0-4.067-1.017-5.592-4.473c-.712-1.525-1.627-4.27-2.745-4.27H5.388v8.642H0Zm5.388 3.965v5.388H7.32c1.525 0 3.355-.508 3.355-2.846c0-1.932-1.22-2.542-2.745-2.542zm18.097-3.965h5.49l7.422 21.858h-5.49l-1.322-4.168h-7.32l-1.321 4.168h-4.779zm0 13.725h4.677l-1.423-4.575c-.509-1.525-.712-3.762-.712-3.762h-.102s-.305 2.237-.813 3.762zM36.6 97.592h4.474V87.121c0-1.22-.203-2.644-.305-3.864h.102c.406 1.119 1.016 2.542 1.626 3.559l5.999 10.777h5.693v-21.86h-4.473v10.472c0 1.22.203 3.05.203 4.27h-.102c-.508-1.322-1.118-2.949-1.626-3.864l-5.795-10.878H36.6zm18.707-11.081c0 7.93 3.864 11.488 9.76 11.488c5.796 0 9.049-3.253 8.642-7.727l-4.778-.508c.407 2.847-1.424 4.27-3.66 4.27c-2.339 0-4.372-1.525-4.372-7.422c0-5.591 1.932-7.421 4.27-7.421c2.44 0 3.864 1.423 3.254 4.88l5.083-.407c.712-4.778-1.83-8.235-8.235-8.235c-6.1-.102-9.964 3.253-9.964 11.082m19.52 11.081h5.389v-9.251h6.609v9.251h5.388V75.734h-5.388v8.235h-6.609v-8.235h-5.388zm19.623 0h14.843v-4.066H99.94v-5.084h7.625v-4.066H99.94V79.8h9.048v-4.067H94.45Zm15.86-21.858h9.048c4.27 0 7.117 2.033 7.117 6.1c0 3.253-2.237 5.287-4.372 5.897c.61.508 1.119 1.321 1.525 2.033c.915 1.83 1.525 3.863 3.457 3.863c.508 0 .915-.203.915-.203l-.407 3.863s-1.118.305-2.135.305c-2.541 0-4.066-1.016-5.591-4.473c-.61-1.525-1.525-4.27-2.745-4.27h-1.22v8.642h-5.389zm5.388 3.965v5.388h1.932c1.525 0 3.355-.508 3.355-2.846c0-1.932-1.22-2.542-2.745-2.542z" />
            <path fill="#2453ff" d="m103.193 39.235l-.813-5.083c-.305-1.627-.915-2.949-1.424-2.949s-.915 1.322-.915 3.05v1.322c0 1.627-1.321 3.05-3.05 3.05h-1.626v3.559h5.286c1.627 0 2.745-1.322 2.542-2.949m-12.098-4.372h-16.98v-.813c0-1.627-.407-3.05-.915-3.05s-1.118 1.322-1.423 2.948l-.814 5.084c-.305 1.627.915 2.948 2.542 2.948h4.982c.508 0 1.016-.101 1.423-.203c-.203.915-.915 1.525-1.83 1.525h-7.015c-1.118 0-2.033-1.017-1.83-2.135l.712-4.27c.203-1.119-.712-2.135-1.83-2.135H33.042c-.712 0-1.423.406-1.728 1.118l-6.609 10.065c-.101.203-.101.407 0 .508l1.322 1.525c.203.204.407.204.61.102l4.473-3.558v21.452c0 1.016.814 1.83 1.83 1.83h9.964c1.017 0 1.83-.814 1.83-1.83V58.45c0-1.016.813-1.83 1.83-1.83H71.37c1.017 0 1.83.814 1.83 1.83v7.524c0 1.016.813 1.83 1.83 1.83h9.963c1.017 0 1.83-.814 1.83-1.83v-8.032h-5.286c-1.627 0-3.05-1.322-3.05-3.05v-5.185c0-1.017.508-1.83 1.22-2.44v6.202c0 1.626 1.321 3.05 3.05 3.05h8.133c1.627 0 3.05-1.322 3.05-3.05V37.913c.203-1.626-1.22-3.05-2.847-3.05" />
          </svg>
          <Typography variant='h5'>Rancher</Typography>
          <Typography color='text.primary'>
          Open-source multi-cluster management and secure enterprise Kubernetes.
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectRancher} />
        </CardContent>
      </Card>
    </>
  )
}

export default RancherConnection
