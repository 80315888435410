// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectAzure from '@components/platform/dialogs/connect-azure'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const KibanaConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 256 328">
            <path fill="#f04e98" d="M256 0H0v294.789z" />
            <path fill="#343741" d="M0 122.88v171.91l122.194-140.71C85.862 134.277 44.288 122.88 0 122.88" />
            <path fill="#00bfb3" d="M148.59 170.54L23.2 314.945l-11.069 12.738h238.736C237.77 263.13 200.302 207.496 148.59 170.54" />
          </svg>
          <Typography variant='h5'>Kibana</Typography>
          <Typography color='text.primary'>
          From data exploration to finding insights to sharing results
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectAzure} />
        </CardContent>
      </Card>
    </>
  )
}

export default KibanaConnection
