// Component Imports
import Permissions from '@views/apps/permissions'

import { db } from '@/app/api/fake-db/apps/permissions'

const getData = () => {
  // Vars
  // const res = await fetch(`${import.meta.env.VITE_REACT_APP_API_URL}/apps/permissions`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch permissions data')
  // }

  // return res.json()
  return db
}

const PermissionsApp = () => {
  // Vars
  const data = getData()

  return <Permissions permissionsData={data} />
}

export default PermissionsApp
