// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectAWS from '@components/platform/dialogs/connect-aws'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const PrometheusConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 256 257">
            <path fill="#da4e31" d="M128.001.667C57.311.667 0 57.971 0 128.664c0 70.69 57.311 127.998 128.001 127.998S256 199.354 256 128.664C256 57.97 198.689.667 128.001.667m0 239.56c-20.112 0-36.419-13.435-36.419-30.004h72.838c0 16.566-16.306 30.004-36.419 30.004m60.153-39.94H67.842V178.47h120.314v21.816zm-.432-33.045H68.185c-.398-.458-.804-.91-1.188-1.375c-12.315-14.954-15.216-22.76-18.032-30.716c-.048-.262 14.933 3.06 25.556 5.45c0 0 5.466 1.265 13.458 2.722c-7.673-8.994-12.23-20.428-12.23-32.116c0-25.658 19.68-48.079 12.58-66.201c6.91.562 14.3 14.583 14.8 36.505c7.346-10.152 10.42-28.69 10.42-40.056c0-11.769 7.755-25.44 15.512-25.907c-6.915 11.396 1.79 21.165 9.53 45.4c2.902 9.103 2.532 24.423 4.772 34.138c.744-20.178 4.213-49.62 17.014-59.784c-5.647 12.8.836 28.818 5.27 36.518c7.154 12.424 11.49 21.836 11.49 39.638c0 11.936-4.407 23.173-11.84 31.958c8.452-1.586 14.289-3.016 14.289-3.016l27.45-5.355c.002-.002-3.987 16.401-19.314 32.197" />
          </svg>
          <Typography variant='h5'>Prometheus</Typography>
          <Typography color='text.primary'>
          Open-source monitoring system with a dimensional data model
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectAWS} />
        </CardContent>
      </Card>
    </>
  )
}

export default PrometheusConnection
