const ContentCompact = props => {
  return (
    <svg width='104' height='66' viewBox='0 0 104 66' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='104' height='66' rx='4' fill='currentColor' fillOpacity='0.02' />
      <rect x='19.4209' y='4.67169' width='64.7547' height='8.8' rx='2' fill='currentColor' fillOpacity='0.08' />
      <rect x='22.3447' y='6.87164' width='3.92453' height='4.4' rx='1' fill='currentColor' fillOpacity='0.3' />
      <rect x='65.5146' y='6.87164' width='3.92453' height='4.4' rx='1' fill='currentColor' fillOpacity='0.3' />
      <rect x='71.4014' y='6.87164' width='3.92453' height='4.4' rx='1' fill='currentColor' fillOpacity='0.3' />
      <rect x='77.2881' y='6.87164' width='3.92453' height='4.4' rx='1' fill='currentColor' fillOpacity='0.3' />
      <rect x='44.3525' y='19.6135' width='40.2264' height='17.6' rx='2' fill='currentColor' fillOpacity='0.08' />
      <rect x='19.4209' y='19.6135' width='19.0455' height='17.6' rx='2' fill='currentColor' fillOpacity='0.08' />
      <rect x='19.4209' y='42.4545' width='65.1591' height='17.6' rx='2' fill='currentColor' fillOpacity='0.08' />
    </svg>
  )
}

export default ContentCompact
