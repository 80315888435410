// MUI Imports
import Grid from '@mui/material/Grid'

// Component Imports
import DialogEditUserInfo from '@views/pages/dialog-examples/DialogEditUserInfo'
import GithubConnection from '@views/pages/devops/connections/GithubConnection'
import ArgoCDConnection from '@views/pages/devops/connections/ArgoCDConnection'
import JenkinsConnection from '@views/pages/devops/connections/JenkinsConnection'
import GitlabConnection from '@views/pages/devops/connections/GitlabConnection'
import SpinnakerConnection from '@views/pages/devops/connections/SpinnakerConnection'
import OpenshiftConnection from '@views/pages/platform/connections/OpenshiftConnection'
import OCPConnection from '@views/pages/platform/connections/OCPConnection'

const DevOpsConnections = () => {
  return (
    <Grid container spacing={6}>
      
      <Grid item xs={12} sm={6} md={4}>
        <GithubConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ArgoCDConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <JenkinsConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <GitlabConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SpinnakerConnection />
      </Grid>
      {/* <Grid item xs={12} sm={6} md={4}>
        <OpenshiftConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OCPConnection />
      </Grid> */}
    </Grid>
  )
}

export default DevOpsConnections
