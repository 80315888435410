// Component Imports
import InvoiceList from '@views/apps/invoice/list'

import { db } from '@/app/api/fake-db/apps/invoice'

const getData = () => {
  // Vars
  // const res = await fetch(`${import.meta.env.VITE_REACT_APP_API_URL}/apps/invoice`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch invoice data')
  // }

  // return res.json()
  return db
}

const InvoiceApp = () => {
  // Vars
  const data = getData()

  return <InvoiceList invoiceData={data} />
}

export default InvoiceApp
