// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectAWS from '@components/platform/dialogs/connect-aws'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const NewRelicConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M8.002 14.31v7.383L12 24V12L1.608 6v4.616ZM12 0L2.823 5.298l3.998 2.308L12 4.616l6.393 3.692v7.384l-5.178 2.99V23.3l9.176-5.3V6Z" />
          </svg>
          <Typography variant='h5'>NewRelic</Typography>
          <Typography color='text.primary'>
          Manage Complex Systems, Improve Client-Side Experiences, Build and Run Better Software
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectAWS} />
        </CardContent>
      </Card>
    </>
  )
}

export default NewRelicConnection
