// MUI Imports
import Grid from '@mui/material/Grid'

// Component Imports
import DialogEditUserInfo from '@views/pages/dialog-examples/DialogEditUserInfo'
import NewRelicConnection from '@views/pages/observability/apm/NewRelicConnection'
import DatadogConnection from '@views/pages/observability/apm/DatadogConnection'
import SplunkConnection from '@views/pages/observability/apm/SplunkConnection'
import AppDynamicsConnection from '@views/pages/observability/apm/AppDynamicsConnection'
import DynatraceConnection from '@views/pages/observability/apm/DynatraceConnection'
import SumoLogicConnection from '@views/pages/observability/apm/SumoLogicConnection'
import OCPConnection from '@views/pages/observability/apm/OCPConnection'

import PrometheusConnection from '@views/pages/observability/monitoring/PrometheusConnection'
import GrafanaConnection from '@views/pages/observability/monitoring/GrafanaConnection'
import ThanosConnection from '@views/pages/observability/monitoring/ThanosConnection'
import CortexConnection from '@views/pages/observability/monitoring/CortexConnection'
import OpenTelemetryConnection from '@views/pages/observability/monitoring/OpenTelemetryConnection'
import MimirConnection from '@views/pages/observability/monitoring/MimirConnection'

import ElasticConnection from '@views/pages/observability/logging/ElasticConnection'
import LogstashConnection from '@views/pages/observability/logging/LogstashConnection'
import KibanaConnection from '@views/pages/observability/logging/KibanaConnection'
import FluentdConnection from '@views/pages/observability/logging/FluentdConnection'
import LokiConnection from '@views/pages/observability/logging/LokiConnection'

const APM = () => {
  return (
    <Grid container spacing={6}>
      
      <Grid item xs={12} sm={6} md={4}>
        <NewRelicConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DatadogConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SplunkConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AppDynamicsConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DynatraceConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SumoLogicConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OCPConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <PrometheusConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <GrafanaConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ThanosConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CortexConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OpenTelemetryConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MimirConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <ElasticConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LogstashConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <KibanaConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FluentdConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LokiConnection />
      </Grid>
    </Grid>
  )
}

export default APM
