// MUI Imports
import Grid from '@mui/material/Grid'

// Component Imports
import DialogEditUserInfo from '@views/pages/dialog-examples/DialogEditUserInfo'
import AWSConnection from '@views/pages/platform/connections/AWSConnection'
import GCPConnection from '@views/pages/platform/connections/GCPConnection'
import AzureConnection from '@views/pages/platform/connections/AzureConnection'
import TanzuConnection from '@views/pages/platform/connections/TanzuConnection'
import RancherConnection from '@views/pages/platform/connections/RancherConnection'
import OpenshiftConnection from '@views/pages/platform/connections/OpenshiftConnection'
// import OCPConnection from '@views/pages/platform/connections/OCPConnection'
import GithubConnection from '@views/pages/devops/connections/GithubConnection'
import ArgoCDConnection from '@views/pages/devops/connections/ArgoCDConnection'
import JenkinsConnection from '@views/pages/devops/connections/JenkinsConnection'
import GitlabConnection from '@views/pages/devops/connections/GitlabConnection'
import SpinnakerConnection from '@views/pages/devops/connections/SpinnakerConnection'

import NewRelicConnection from '@views/pages/observability/apm/NewRelicConnection'
import DatadogConnection from '@views/pages/observability/apm/DatadogConnection'
import SplunkConnection from '@views/pages/observability/apm/SplunkConnection'
import AppDynamicsConnection from '@views/pages/observability/apm/AppDynamicsConnection'
import DynatraceConnection from '@views/pages/observability/apm/DynatraceConnection'
import SumoLogicConnection from '@views/pages/observability/apm/SumoLogicConnection'
import OCPConnection from '@views/pages/observability/apm/OCPConnection'

import PrometheusConnection from '@views/pages/observability/monitoring/PrometheusConnection'
import GrafanaConnection from '@views/pages/observability/monitoring/GrafanaConnection'
import ThanosConnection from '@views/pages/observability/monitoring/ThanosConnection'
import CortexConnection from '@views/pages/observability/monitoring/CortexConnection'
import OpenTelemetryConnection from '@views/pages/observability/monitoring/OpenTelemetryConnection'
import MimirConnection from '@views/pages/observability/monitoring/MimirConnection'

import ElasticConnection from '@views/pages/observability/logging/ElasticConnection'
import LogstashConnection from '@views/pages/observability/logging/LogstashConnection'
import KibanaConnection from '@views/pages/observability/logging/KibanaConnection'
import FluentdConnection from '@views/pages/observability/logging/FluentdConnection'
import LokiConnection from '@views/pages/observability/logging/LokiConnection'
import ClairConnection from '@/views/pages/security/integrations/ClairConnection'
import SeleniumConnection from '@/views/pages/security/integrations/SeleniumConnection'
import SonarQubeConnection from '@/views/pages/security/integrations/SonarQubeConnection'
import TrivyConnection from '@/views/pages/security/integrations/TrivyConnection'
import AKSKeyVaultConnection from '@/views/pages/security/integrations/AKSKeyVaultConnection'
import EKSKeyVaultConnection from '@/views/pages/security/integrations/EKSKeyVaultConnection'
import GKEKeyVaultConnection from '@/views/pages/security/integrations/GKEKeyVaultConnection'

const PlatformConnections = () => {
  return (
    <Grid container spacing={6}>
      
      <Grid item xs={12} sm={6} md={4}>
        <AWSConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <GCPConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AzureConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TanzuConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RancherConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OpenshiftConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OCPConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <GithubConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ArgoCDConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <JenkinsConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <GitlabConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SpinnakerConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <NewRelicConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DatadogConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SplunkConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <AppDynamicsConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DynatraceConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SumoLogicConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OCPConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <PrometheusConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <GrafanaConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ThanosConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CortexConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <OpenTelemetryConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MimirConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <ElasticConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LogstashConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <KibanaConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FluentdConnection />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LokiConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <ClairConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <SeleniumConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <SonarQubeConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TrivyConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <AKSKeyVaultConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <EKSKeyVaultConnection />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <GKEKeyVaultConnection />
      </Grid>
    </Grid>
  )
}

export default PlatformConnections
