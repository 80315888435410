'use client'

// React Imports
import { useEffect, useState } from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import IconButton from '@mui/material/IconButton'
import { FormControlLabel } from '@mui/material'

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Controller, useForm } from 'react-hook-form'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { object, minLength, string, email } from 'valibot'


import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'

// Third-party Imports
import { useDropzone } from 'react-dropzone'


// MUI Imports
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import { useDispatch, useSelector } from 'react-redux';
import { login, loginWithToken } from "@/redux-store/auth.slice";
import { connectgcp, resetConnectionStatus } from "@/redux-store/platform.slice";

// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 160
  }
}))

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

const FileUploaderMultiple = ({setCredentialsFile}) => {
  // States
  const [files, setFiles] = useState([])

  useEffect(() => {
    console.log("files", files);
    setCredentialsFile(files);
  }, [files])

  // Hooks
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      // 'image/*': ['.png', '.jpg', '.jpeg', '.gif']
      'application/json': ['.json']
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file)))
    }
  })

  const renderFilePreview = file => {
    if (file.type.startsWith('image')) {
      return <img width={38} height={38} alt={file.name} src={URL.createObjectURL(file)} />
    } else {
      return <i className='ri-file-text-line' />
    }
  }

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)

    setFiles([...filtered])
  }

  const fileList = files.map(file => (
    <ListItem key={file.name}>
      <div className='file-details'>
        <div className='file-preview'>{renderFilePreview(file)}</div>
        <div>
          <Typography className='file-name'>{file.name}</Typography>
          <Typography className='file-size' variant='body2'>
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </div>
      </div>
      <IconButton onClick={() => handleRemoveFile(file)}>
        <i className='ri-close-line text-xl' />
      </IconButton>
    </ListItem>
  ))

  const handleRemoveAllFiles = () => {
    setFiles([])
  }

  // Hooks
  const theme = useTheme()

  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div className='flex gap-3 items-center flex-col md:flex-row'>
          <Img alt='Upload img' src='/images/misc/file-upload.png' className='max-bs-[160px] max-is-full bs-full' />
          <div className='flex flex-col md:[text-align:unset] text-center'>
            <HeadingTypography variant='h5'>Drop gcp credential json file.</HeadingTypography>
            <Typography>
              Drop gcp credentials file here or click{' '}
              <a href='/' onClick={e => e.preventDefault()} className='text-textPrimary no-underline'>
                browse
              </a>{' '}
              through your machine
            </Typography>
          </div>
        </div>
      </div>
      {files.length ? (
        <>
          <List>{fileList}</List>
          {/* <div className='buttons'>
            <Button color='error' variant='outlined' onClick={handleRemoveAllFiles}>
              Remove All
            </Button>
            <Button variant='contained'>Upload Files</Button>
          </div> */}
        </>
      ) : null}
    </>
  )
}

// Vars
const initialData = {
  name: 'Oliver',
  project_id: 'Queen',
  credentialsfile: null
}

const status = ['Status', 'Active', 'Inactive', 'Suspended']
const languages = ['English', 'Spanish', 'French', 'German', 'Hindi']
const countries = ['Select Country', 'France', 'Russia', 'China', 'UK', 'US']

const schema = object({
  email: string([minLength(1, 'This field is required'), email('Email is invalid')]),
  password: string([
    minLength(1, 'This field is required'),
    minLength(5, 'Password must be at least 5 characters long')
  ])
})

const ConnectGCP = ({ open, setOpen, data }) => {
  // States
  const [connectionData, setConnectionData] = useState(data || initialData)
  const [inprogress, setInprogress] = useState(false);
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();
  const connectionStatus = useSelector((state) => state?.platform?.connectionStatus);

  useEffect(()=> {
    console.log("connectionStatus", connectionStatus)
  }, [connectionStatus])
  
  const handleClose = () => {
    setOpen(false)
    setConnectionData(initialData)
    setInprogress(false);
    setConnected(false);
    return dispatch(resetConnectionStatus("todo"));
  }

  const handleConnect = () => {
    setInprogress(true);
    setTimeout(() => {
      return dispatch(connectgcp(connectionData));
    }, 10000);
  }

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: valibotResolver(schema),
    defaultValues: {
      email: 'email@example.com',
      password: 'yourpassword'
    }
  })

  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth='md' scroll='body'>
      <DialogTitle
        variant='h4'
        className='flex gap-2 flex-col text-center pbs-10 pbe-6 pli-10 sm:pbs-16 sm:pbe-6 sm:pli-16'
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 256 206">
          <path fill="#ea4335" d="m170.252 56.819l22.253-22.253l1.483-9.37C153.437-11.677 88.976-7.496 52.42 33.92C42.267 45.423 34.734 59.764 30.717 74.573l7.97-1.123l44.505-7.34l3.436-3.513c19.797-21.742 53.27-24.667 76.128-6.168z" />
          <path fill="#4285f4" d="M224.205 73.918a100.249 100.249 0 0 0-30.217-48.722l-31.232 31.232a55.515 55.515 0 0 1 20.379 44.037v5.544c15.35 0 27.797 12.445 27.797 27.796c0 15.352-12.446 27.485-27.797 27.485h-55.671l-5.466 5.934v33.34l5.466 5.231h55.67c39.93.311 72.553-31.494 72.864-71.424a72.303 72.303 0 0 0-31.793-60.453" />
          <path fill="#34a853" d="M71.87 205.796h55.593V161.29H71.87a27.275 27.275 0 0 1-11.399-2.498l-7.887 2.42l-22.409 22.253l-1.952 7.574c12.567 9.489 27.9 14.825 43.647 14.757" />
          <path fill="#fbbc05" d="M71.87 61.426C31.94 61.663-.237 94.227.001 134.158a72.301 72.301 0 0 0 28.222 56.88l32.248-32.246c-13.99-6.322-20.208-22.786-13.887-36.776c6.32-13.99 22.786-20.208 36.775-13.888a27.796 27.796 0 0 1 13.887 13.888l32.248-32.248A72.224 72.224 0 0 0 71.87 61.427" />
        </svg>
          
        {inprogress && (connectionStatus !== "done") && <div className="flex justify-center">
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
        </div>}
        {!inprogress && (connectionStatus !== "done") && `Connect GCP` }
        {(connectionStatus === "done") && (
          <>
            <div className="flex justify-center">
              <Box sx={{ display: 'flex' }}>
                <CheckCircleIcon color="success" fontSize="large" />
              </Box>
            </div>
              
            Connected Successfully
          </>
          ) }
        {inprogress && (connectionStatus !== "done") && `Connecting GCP` }
        <Typography component='span' className='flex flex-col text-center'>
          Updating user details will receive a privacy audit.
        </Typography>
      </DialogTitle>
      <form noValidate onSubmit={handleSubmit(handleConnect)}>
        <DialogContent className='overflow-visible pbs-0 pbe-6 pli-10 sm:pli-16'>
          <IconButton onClick={handleClose} className='absolute block-start-4 inline-end-4'>
            <i className='ri-close-line text-textSecondary' />
          </IconButton>
          {!inprogress && (connectionStatus !== "done") &&
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Name'
                placeholder='AWS Connection Example'
                value={connectionData?.name}
                onChange={e => setConnectionData({ ...connectionData, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Project ID'
                placeholder='Tax-7490'
                value={connectionData?.project_id}
                onChange={e => setConnectionData({ ...connectionData, project_id: e.target.value })}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Region</InputLabel>
                <Select
                  label='Region'
                  value={connectionData?.country?.toLowerCase().replace(/\s+/g, '-')}
                  onChange={e => setConnectionData({ ...connectionData, country: e.target.value })}
                >
                  {countries.map((country, index) => (
                    <MenuItem key={index} value={country.toLowerCase().replace(/\s+/g, '-')}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
            </Grid> */}
            <Grid item xs={12}>
              {/* <TextField
                fullWidth
                label='Description'
                placeholder='This is an example AWS connection credentials'
                value={connectionData?.userName}
                onChange={e => setConnectionData({ ...connectionData, userName: e.target.value })}
              /> */}
              <FileUploaderMultiple  setCredentialsFile={(files) => {
                  console.log("files", files);
                  setConnectionData({ ...connectionData, credentialsfile: files[0] })
                }} />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Credentials Type</InputLabel>
                <Select
                  label='Credentials Type'
                  value={connectionData?.status}
                  onChange={e => setConnectionData({ ...connectionData, status: e.target.value })}
                >
                  {status.map((status, index) => (
                    <MenuItem key={index} value={status.toLowerCase().replace(/\s+/g, '-')}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Tags</InputLabel>
                <Select
                  label='Tags'
                  multiple
                  value={connectionData?.language}
                  onChange={e => setConnectionData({ ...connectionData, language: e.target.value })}
                  renderValue={selected => (
                    <div className='flex items-center gap-2 flex-wrap'>
                      {selected.map(value => (
                        <Chip key={value} label={value} className='capitalize' size='small' />
                      ))}
                    </div>
                  )}
                >
                  {languages.map((language, index) => (
                    <MenuItem key={index} value={language.toLowerCase().replace(/\s+/g, '-')}>
                      {language}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            
            
            {/* <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Secret Key'
                placeholder='+ 123 456 7890'
                value={connectionData?.contact}
                onChange={e => setConnectionData({ ...connectionData, contact: e.target.value })}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={6}>
              <TextField
                  fullWidth
                  label='Billing Email'
                  placeholder='johnDoe@email.com'
                  value={connectionData?.billingEmail}
                  onChange={e => setConnectionData({ ...connectionData, billingEmail: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                  fullWidth
                  label='Last Name'
                  placeholder='Doe'
                  value={connectionData?.lastName}
                  onChange={e => setConnectionData({ ...connectionData, lastName: e.target.value })}
                />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch defaultChecked={connectionData?.useAsBillingAddress} />}
                label='Use as a billing address?'
              />
            </Grid> */}
          </Grid>}
        </DialogContent>
        { !inprogress && (connectionStatus !== "done") && <DialogActions className='gap-2 justify-center pbs-0 pbe-10 pli-10 sm:pbe-16 sm:pli-16'>
          {/* <Button variant='contained' onClick={handleConnect} type='submit'> */}
          <Button variant='contained' type='submit'>
            Connect
          </Button>
          <Button variant='outlined' color='secondary' type='reset' onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>}
      </form>
    </Dialog>
  )
}

export default ConnectGCP

