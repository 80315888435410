export const db = {
  vehicles: [
    {
      id: 1,
      location: 468031,
      startCity: 'John',
      startCountry: 'J',
      endCity: 'john@abc.com',
      endCountry: 'Admin',
      warnings: 'active',
      progress: 49
    },
    {
      id: 2,
      location: 302781,
      startCity: 'David',
      startCountry: 'D',
      endCity: 'david@abc.com',
      endCountry: 'Devsecops',
      warnings: 'active',
      progress: 24
    },
    {
      id: 3,
      location: 715822,
      startCity: 'mark',
      startCountry: 'M',
      endCity: 'mark@abc.com',
      endCountry: 'SRE',
      warnings: 'active',
      progress: 7
    },
    {
      id: 4,
      location: 451430,
      startCity: 'Steven',
      startCountry: 'S',
      endCity: 'steven@cde.com',
      endCountry: 'PlatformEngineer',
      warnings: 'active',
      progress: 95
    },
    {
      id: 5,
      location: 921577,
      startCity: 'Cergy',
      startCountry: 'C',
      endCity: 'cergy@edr.com',
      endCountry: 'Ops Admin',
      warnings: 'active',
      progress: 65
    },
    {
      id: 6,
      location: 480957,
      startCity: 'Villefranche-sur-Saône',
      startCountry: 'France',
      endCity: 'Halle',
      endCountry: 'Germany',
      warnings: 'Ecu Not Responding',
      progress: 55
    },
    {
      id: 7,
      location: 330178,
      startCity: 'Mâcon',
      startCountry: 'France',
      endCity: 'Bochum',
      endCountry: 'Germany',
      warnings: 'Fuel Problems',
      progress: 74
    },
    {
      id: 8,
      location: 595525,
      startCity: 'Fullerton',
      startCountry: 'USA',
      endCity: 'Lübeck',
      endCountry: 'Germany',
      warnings: 'No Warnings',
      progress: 100
    },
    {
      id: 9,
      location: 182964,
      startCity: 'Saintes',
      startCountry: 'France',
      endCity: 'Roma',
      endCountry: 'Italy',
      warnings: 'Oil Leakage',
      progress: 82
    },
    {
      id: 10,
      location: 706085,
      startCity: 'Fort Wayne',
      startCountry: 'USA',
      endCity: 'Mülheim an der Ruhr',
      endCountry: 'Germany',
      warnings: 'Oil Leakage',
      progress: 49
    },
    {
      id: 11,
      location: 523708,
      startCity: 'Albany',
      startCountry: 'USA',
      endCity: 'Wuppertal',
      endCountry: 'Germany',
      warnings: 'Temperature Not Optimal',
      progress: 66
    },
    {
      id: 12,
      location: 676485,
      startCity: 'Toledo',
      startCountry: 'USA',
      endCity: 'Magdeburg',
      endCountry: 'Germany',
      warnings: 'Temperature Not Optimal',
      progress: 7
    },
    {
      id: 13,
      location: 514437,
      startCity: 'Houston',
      startCountry: 'USA',
      endCity: 'Wiesbaden',
      endCountry: 'Germany',
      warnings: 'Fuel Problems',
      progress: 27
    },
    {
      id: 14,
      location: 300198,
      startCity: 'West Palm Beach',
      startCountry: 'USA',
      endCity: 'Dresden',
      endCountry: 'Germany',
      warnings: 'Temperature Not Optimal',
      progress: 90
    },
    {
      id: 15,
      location: 960090,
      startCity: 'Fort Lauderdale',
      startCountry: 'USA',
      endCity: 'Kiel',
      endCountry: 'Germany',
      warnings: 'No Warnings',
      progress: 81
    },
    {
      id: 16,
      location: 878423,
      startCity: 'Schaumburg',
      startCountry: 'USA',
      endCity: 'Berlin',
      endCountry: 'Germany',
      warnings: 'Fuel Problems',
      progress: 21
    },
    {
      id: 17,
      location: 318119,
      startCity: 'Mundolsheim',
      startCountry: 'France',
      endCity: 'München',
      endCountry: 'Germany',
      warnings: 'No Warnings',
      progress: 26
    },
    {
      id: 18,
      location: 742500,
      startCity: 'Fargo',
      startCountry: 'USA',
      endCity: 'Salerno',
      endCountry: 'Italy',
      warnings: 'Temperature Not Optimal',
      progress: 80
    },
    {
      id: 19,
      location: 469399,
      startCity: 'München',
      startCountry: 'Germany',
      endCity: 'Ath',
      endCountry: 'Belgium',
      warnings: 'Ecu Not Responding',
      progress: 50
    },
    {
      id: 20,
      location: 411175,
      startCity: 'Chicago',
      startCountry: 'USA',
      endCity: 'Neuss',
      endCountry: 'Germany',
      warnings: 'Oil Leakage',
      progress: 44
    },
    {
      id: 21,
      location: 753525,
      startCity: 'Limoges',
      startCountry: 'France',
      endCity: 'Messina',
      endCountry: 'Italy',
      warnings: 'Temperature Not Optimal',
      progress: 55
    },
    {
      id: 22,
      location: 882341,
      startCity: 'Cesson-Sévigné',
      startCountry: 'France',
      endCity: 'Napoli',
      endCountry: 'Italy',
      warnings: 'No Warnings',
      progress: 48
    },
    {
      id: 23,
      location: 408270,
      startCity: 'Leipzig',
      startCountry: 'Germany',
      endCity: 'Tournai',
      endCountry: 'Belgium',
      warnings: 'Ecu Not Responding',
      progress: 73
    },
    {
      id: 24,
      location: 276904,
      startCity: 'Aulnay-sous-Bois',
      startCountry: 'France',
      endCity: 'Torino',
      endCountry: 'Italy',
      warnings: 'Fuel Problems',
      progress: 30
    },
    {
      id: 25,
      location: 159145,
      startCity: 'Paris 19',
      startCountry: 'France',
      endCity: 'Dresden',
      endCountry: 'Germany',
      warnings: 'No Warnings',
      progress: 60
    }
  ]
}
