// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectOpenshift from '@components/platform/dialogs/connect-openshift'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const OpenshiftConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="5em" viewBox="0 0 256 237">
            <path fill="#da2430" d="m74.84 106.893l-40.869 14.87c.525 6.552 1.652 13.024 3.24 19.366l38.818-14.137c-1.244-6.552-1.689-13.32-1.18-20.1m180.595-45.19c-2.85-5.879-6.147-11.561-9.966-16.916l-40.857 14.87c4.755 4.864 8.743 10.33 12.007 16.176L255.445 61.7z" />
            <path fill="#da2430" d="M182.95 61.461c-5.14-4.46-10.946-8.31-17.408-11.324h-.003C127.6 32.449 82.33 48.905 64.642 86.859a73.976 73.976 0 0 0-2.653 6.487c-2.208 6.423-3.498 12.99-3.991 19.546l-.144.054l-.011.144l-24.596 8.95l-15.826 5.849l-.007-.088l-.434.158c-1.536-19.435 1.74-39.512 10.553-58.411a117.952 117.952 0 0 1 3.687-7.246c29.054-54.116 95.164-76.736 150.918-50.33a115.314 115.314 0 0 1 30.557 21.074a116.597 116.597 0 0 1 15.78 17.94L187.618 65.86a79.69 79.69 0 0 0-.635-.641l-.113.041a78.61 78.61 0 0 0-3.919-3.798" />
            <path fill="#e82429" d="m19.261 193.896l-.064.024A118.404 118.404 0 0 1 .939 154.347l38.825-14.14l.002.003l.024.125l.252-.093l.006.015c1.994 10.56 6.145 20.635 12.198 29.494a75.287 75.287 0 0 0 7.722 9.326l-.154.057l.294.308l-40.488 14.97c-.12-.171-.24-.343-.359-.516" />
            <path fill="#da2430" d="M173.465 183.447c-21.051 13.017-48.053 15.532-72.113 4.312a75.139 75.139 0 0 1-22.118-15.695l-40.772 14.844l.313.437l-.02.008c11.21 16.016 26.502 29.404 45.266 38.298c40.47 19.163 86.387 12.501 119.634-13.284c14.888-11.152 27.317-26.016 35.713-44.02c8.819-18.895 12.076-38.964 10.515-58.384l-1.136.414c-.015-.208-.03-.416-.047-.624l-40.49 14.957l.002.004a76.457 76.457 0 0 1-6.806 26.46c-6.315 13.753-16.164 24.708-27.94 32.273" />
            <path fill="#e82429" d="m218.552 75.13l.607-.222v-.001a117.732 117.732 0 0 1 11.454 42.055l-40.773 14.834l.022-.304l-.77.285c1.11-15.088-2.275-30.093-9.435-43.123l38.548-14.25l.002-.004c.116.243.231.486.345.73" />
            <path fill="#c22035" d="M74.89 106.654L34.31 121.65c.52 6.61 1.64 13.136 3.219 19.532l38.546-14.258c-1.247-6.622-1.695-13.438-1.169-20.274m179.321-45.567c-2.83-5.929-6.106-11.658-9.898-17.059L203.76 59.02c4.72 4.906 8.68 10.418 11.92 16.315l38.542-14.256z" />
            <path fill="#ac223b" d="m34.308 121.653l40.482-14.829l-.165 8.133l-39.056 14.749l-1.266-8.063zm169.458-62.756l41.113-14.108l4.273 6.449l-39.946 14.121l-5.434-6.465z" />
            <path fill="#b92135" d="m38.764 187.201l40.53-14.749l12.258 11.565l-42.503 15.956l-10.283-12.776zm210.616-77.339l-41.165 14.844l-3.032 16.478l43.892-15.643l.311-15.677z" />
          </svg>
          <Typography variant='h5'>Openshift</Typography>
          <Typography color='text.primary'>
          Managed private cluster offering, built around a core of application containers
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectOpenshift} />
        </CardContent>
      </Card>
    </>
  )
}

export default OpenshiftConnection
