import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'

import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/300.css"; // Specify weight
import "@fontsource/inter/400.css"; // Specify weight
import "@fontsource/inter/500.css"; // Specify weight
import "@fontsource/inter/600.css"; // Specify weight
import "@fontsource/inter/700.css"; // Specify weight
import "@fontsource/inter/800.css"; // Specify weight
import "@fontsource/inter/900.css"; // Specify weight
import "@fontsource/inter/latin-300.css"; // Specify weight and style
import "@fontsource/inter/latin-400.css"; // Specify weight and style
import "@fontsource/inter/latin-500.css"; // Specify weight and style
import "@fontsource/inter/latin-600.css"; // Specify weight and style
import "@fontsource/inter/latin-700.css"; // Specify weight and style
import "@fontsource/inter/latin-800.css"; // Specify weight and style
import "@fontsource/inter/latin-900.css"; // Specify weight and style

import './index.css'


ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
)
