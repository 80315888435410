// MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Component Imports
import ConnectGCP from '@components/platform/dialogs/connect-gcp'
import OpenDialogOnElementClick from '@components/dialogs/OpenDialogOnElementClick'

const LogstashConnection = () => {
  // Vars
  const buttonProps = {
    variant: 'contained',
    children: 'Connect'
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center text-center gap-4'>
          {/* <i className='ri-user-3-line text-[28px] text-textPrimary' /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 256 307">
            <path fill="#fec514" d="M122.435 189.217H0V0h11.13c61.474 0 111.305 49.83 111.305 111.304z" />
            <path fill="#343741" d="M116.87 306.086h5.565V189.217H0c0 64.545 52.324 116.87 116.87 116.87" />
            <path fill="#00bfb3" d="M150.261 306.086H256V189.217H150.261z" />
          </svg>
          <Typography variant='h5'>Logstash</Typography>
          <Typography color='text.primary'>
          Integrates data from any source, in any format with flexible, open source collection
          </Typography>
          <OpenDialogOnElementClick element={Button} elementProps={buttonProps} dialog={ConnectGCP} />
        </CardContent>
      </Card>
    </>
  )
}

export default LogstashConnection
