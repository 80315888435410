// Third-party Imports
import 'react-perfect-scrollbar/dist/css/styles.css'

// Config Imports
import { i18n } from '@configs/i18n'

// Style Imports
import '@/app/globals.css'

// Generated Icon CSS Imports
import '@assets/iconify-icons/generated-icons.css'

import { Outlet } from 'react-router-dom'

// export const metadata = {
//   title: 'Materio - Material Design Next.js Admin Template',
//   description:
//     'Materio - Material Design Next.js Admin Dashboard Template - is the most developer friendly & highly customizable Admin Dashboard Template based on MUI v5.'
// }

// const RootLayout = ({ children, params }) => {
const RootLayout = (props) => {
  // Vars
  // const direction = i18n.langDirection[params.lang]

  // return (
  //   <html id='__next' lang={params.lang} dir={direction}>
  //     <body className='flex is-full min-bs-full flex-auto flex-col'>
  //       {children}
  //     </body>
  //   </html>
  // )

    return (
        <Outlet />
    )

}

export default RootLayout
