/**
 * ! The server actions below are used to fetch the static data from the fake-db. If you're using an ORM
 * ! (Object-Relational Mapping) or a database, you can swap the code below with your own database queries.
 */
'use server'

// Data Imports
import { db as eCommerceData } from '@/fake-db/apps/ecommerce'
import { db as academyData } from '@/fake-db/apps/academy'
import { db as vehicleData } from '@/fake-db/apps/logistics'
import { db as invoiceData } from '@/fake-db/apps/invoice'
import { db as userData } from '@/fake-db/apps/user-list'
import { db as permissionData } from '@/fake-db/apps/permissions'
import { db as profileData } from '@/fake-db/pages/user-profile'
import { db as faqData } from '@/fake-db/pages/faq'
import { db as pricingData } from '@/fake-db/pages/pricing'
import { db as statisticsData } from '@/fake-db/pages/widget-examples'

export const getEcommerceData = () => {
  return eCommerceData
}

export const getAcademyData = () => {
  return academyData
}

export const getLogisticsData = () => {
  return vehicleData
}

export const getInvoiceData = () => {
  return invoiceData
}

export const getUserData = () => {
  return userData
}

export const getPermissionsData = () => {
  return permissionData
}

export const getProfileData = () => {
  return profileData
}

export const getFaqData = () => {
  return faqData
}

export const getPricingData = () => {
  return pricingData
}

export const getStatisticsData = () => {
  return statisticsData
}
