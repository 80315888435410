// Component Imports
import UserList from '@views/apps/user/list'

import { db } from '@/app/api/fake-db/apps/user-list'

const getData = () => {
  // Vars
  // const res = await fetch(`${import.meta.env.VITE_REACT_APP_API_URL}/apps/user-list`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch userData')
  // }

  // return res.json()
  return db
}

const UserListApp = () => {
  // Vars
  const data = getData()

  return <UserList userData={data} />
}

export default UserListApp
