// Third-party Imports
import { configureStore } from '@reduxjs/toolkit'

// Slice Imports
import chatReducer from '@/redux-store/slices/chat'
import calendarReducer from '@/redux-store/slices/calendar'
import kanbanReducer from '@/redux-store/slices/kanban'
import emailReducer from '@/redux-store/slices/email'

import authReducer from "./auth.slice";
import platformReducer from "./platform.slice";
import alertsReducer from "./alert.slice";
import appconfigReducer from "./appconfig.slice";

export const store = configureStore({
  reducer: {
    chatReducer,
    calendarReducer,
    kanbanReducer,
    emailReducer,
    auth: authReducer,
    platform: platformReducer,
    alert: alertsReducer,
    appconfig: appconfigReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
})
