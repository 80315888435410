// MUI Imports
import Grid from '@mui/material/Grid'

// Component Imports
import ProjectListTable from './ProjectListTable'
import UserActivityTimeLine from './UserActivityTimeline'
import InvoiceListTable from './InvoiceListTable'

import { db } from '@/app/api/fake-db/apps/invoice'

const getData = () => {
  // const res = await fetch(`/apps/invoice`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch invoice data')
  // }

  // return res.json()
  return db
}

const OverViewTab = () => {
  // Vars
  const invoiceData = getData()

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <ProjectListTable />
      </Grid>
      <Grid item xs={12}>
        <UserActivityTimeLine />
      </Grid>
      <Grid item xs={12}>
        <InvoiceListTable invoiceData={invoiceData} />
      </Grid>
    </Grid>
  )
}

export default OverViewTab
