// Component Imports
import FAQ from '@views/pages/faq'

import { db } from '@/app/api/fake-db/pages/faq'

const getFaqData = () => {
  // Vars
  // const res = await fetch(`${import.meta.env.VITE_REACT_APP_API_URL}/pages/faq`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch faqData')
  // }

  // return res.json()
  return db
}

const FAQPage = () => {
  // Vars
  const data = getFaqData()

  return <FAQ data={data} />
}

export default FAQPage
