import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

import BlankLayout from '@layouts/BlankLayout';
import LoginPage from '@/pages/LoginPage';

import RootLayout from "@/app/lang/layout";
import DashboardLayout from "@/app/lang/dashboard/layout";

import DashboardSummary from '@/app/lang/dashboard/dashboards/summary/page';
import PlatformConnections from '@/app/lang/platform/platform/connections/view/page';
import DevOpsConnections from '@/app/lang/devops/devops/connections/view/page';
import DevOpsConnectionList from '@/app/lang/devops/devops/connections/list/page';
import PlatformConnectionList from '@/app/lang/platform/platform/connections/list/page';
import APM from '@/app/lang/observability/observability/apm/page';
import Monitoring from '@/app/lang/observability/observability/monitoring/page';
import Logging from '@/app/lang/observability/observability/logging/page';

// pages
import RegisterV2Page from '@/views/Register';
import PlatformResources from '@/app/lang/platform/platform/resources/page';
import DevOpsResources from '@/app/lang/devops/devops/resources/page';
import PlatformBlueprints from '@/app/lang/platform/platform/blueprints/page';
import ECommerceProductsList from '@/app/lang/platform/platform/products/list/page';
import DevOpsBlueprints from '@/app/lang/devops/devops/blueprints/page';
import PlatformEnvironments from '@/app/lang/platform/platform/environments/page';

import DashboardECommerce from '@/app/lang/devops/devops/overview/page';
import CostOverview from '@/app/lang/cost/cost/overview/page';
import AIOverview from '@/app/lang/ai/ai/overview/page';
import BlueprintGenerator from '@/app/lang/ai/ai/blueprintgen/page';

import ProfilePage from '@/app/lang/dashboard/pages/user-profile/page';
import AccountSettingsPage from '@/app/lang/dashboard/pages/account-settings/page';
import PricePage from '@/app/lang/dashboard/pages/pricing/page';
import FAQPage from '@/app/lang/dashboard/pages/faq/page';

import { history } from "@/utils";
import UserListApp from "@/app/lang/dashboard/apps/user/list/page";
import InvoiceApp from "@/app/lang/dashboard/apps/invoice/list/page";
import UserViewTab from "@/app/lang/dashboard/apps/user/view/page";
import PreviewPage from "@/app/lang/dashboard/apps/invoice/preview/id/page";
import EditPage from "@/app/lang/dashboard/apps/invoice/edit/id/page";
import InvoiceAdd from "@/app/lang/dashboard/apps/invoice/add/page";

import RolesApp from "@/app/lang/dashboard/apps/roles/page";
import PermissionsApp from "@/app/lang/dashboard/apps/permissions/page";
import CalendarApp from "@/app/lang/dashboard/apps/calendar/page";
import ComingSoon from "@/views/pages/misc/ComingSoon";
import KanbanPage from "@/components/kanban/page";
import AcademyDashboard from "@/app/lang/security/security/overview/page";
import LogisticsDashboard from "@/app/lang/dashboard/dashboards/logistics/page";
import DashboardAnalytics from "@/app/lang/dashboard/dashboards/analytics/page";
import OrdersListPage from "@/app/lang/platform/platform/orders/list/page";
import ChatApp from "@/components/chat/page";

export const AppRouter = (props: any) => {

    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <Routes>
            <Route path="/" element={<BlankLayout systemMode={"light"} />}>
                <Route index element={<Navigate to="/en/login" />} />
                <Route path="en" element={<RootLayout/>}> 
                    <Route index element={<Navigate to="/en/login" />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="register" element={<RegisterV2Page mode="system" />} />
                    <Route path="dashboards" element={<DashboardLayout lang="en" />}>
                        {/* <Route path="summary" element={<DashboardSummary />} /> */}
                        <Route path="summary" element={<LogisticsDashboard />} />
                        <Route path="kanban" element={<KanbanPage />} />
                        <Route path="chat" element={<ChatApp />} />
                    </Route>
                    <Route path="platform" element={<DashboardLayout lang="en" />}>
                        <Route path="overview" element={<DashboardSummary />} />
                        <Route path="overview1" element={<DashboardSummary />} />
                        <Route path="connections">
                            <Route path="overview" element={<PlatformConnections />} />    
                            {/* <Route path="list" element={<PlatformConnectionList />} />     */}
                            <Route path="list" element={<OrdersListPage />} />    
                        </Route>
                        {/* <Route path="resources" element={<PlatformResources />} /> */}
                        <Route path="resources" element={<OrdersListPage />} />
                        {/* <Route path="blueprints" element={<PlatformBlueprints />} /> */}
                        <Route path="blueprints" element={<ECommerceProductsList />} />
                        <Route path="environments" element={<PlatformEnvironments />} />
                    </Route>
                    <Route path="devops" element={<DashboardLayout lang="en" />}>
                        <Route path="overview" element={<DashboardAnalytics />} />
                        <Route path="connections">
                            <Route path="overview" element={<DevOpsConnections />} />    
                            <Route path="list" element={<DevOpsConnectionList />} />    
                        </Route>
                        <Route path="resources" element={<DevOpsResources />} />
                        <Route path="blueprints" element={<DevOpsBlueprints />} />
                    </Route>
                    <Route path="backup" element={<DashboardLayout lang="en" />}>
                        <Route path="overview" element={<DashboardECommerce />} />
                        <Route path="connections">
                            <Route path="overview" element={<DevOpsConnections />} />    
                            <Route path="list" element={<DevOpsConnectionList />} />    
                        </Route>
                        <Route path="resources" element={<DevOpsResources />} />
                        <Route path="blueprints" element={<DevOpsBlueprints />} />
                    </Route>
                    <Route path="security" element={<DashboardLayout lang="en" />}>
                        <Route path="overview" element={<AcademyDashboard />} />
                        <Route path="connections">
                            <Route path="overview" element={<DevOpsConnections />} />    
                            <Route path="list" element={<DevOpsConnectionList />} />    
                        </Route>
                        <Route path="resources" element={<DevOpsResources />} />
                        <Route path="blueprints" element={<DevOpsBlueprints />} />
                    </Route>
                    <Route path="observability" element={<DashboardLayout lang="en" />}>
                        <Route path="overview" element={<DashboardECommerce />} />
                        <Route path="dorametrics" element={<ComingSoon mode="system" />} />
                        <Route path="apm" element={<APM />} />
                        <Route path="monitoring" element={<Monitoring />} />
                        <Route path="logging" element={<Logging />} />
                    </Route>
                    <Route path="workflows" element={<DashboardLayout lang="en" />}>
                        <Route path="overview" element={<DashboardECommerce />} />
                        <Route path="schedules" element={<PlatformResources />} />
                        <Route path="triggers" element={<PlatformResources />} />
                    </Route>
                    <Route path="cost" element={<DashboardLayout lang="en" />}>
                        <Route path="overview" element={<CostOverview />} />
                        {/* <Route path="connections" element={<PlatformConnections />} />
                        <Route path="resources" element={<PlatformResources />} />
                        <Route path="blueprints" element={<PlatformBlueprints />} /> */}
                    </Route>
                    <Route path="ai" element={<DashboardLayout lang="en" />}>
                        <Route path="overview" element={<AIOverview />} />
                        <Route path="blueprintgen" element={<BlueprintGenerator />} />
                        {/* <Route path="resources" element={<PlatformResources />} />
                        <Route path="blueprints" element={<PlatformBlueprints />} /> */}
                    </Route>
                    <Route path="pages" element={<DashboardLayout lang="en" />}>
                        <Route path="user-profile" element={<ProfilePage />} />
                        <Route path="connections" element={<PlatformConnections />} />
                        <Route path="account-settings" element={<AccountSettingsPage />} />
                        <Route path="pricing" element={<PricePage />} />
                        <Route path="faq" element={<FAQPage />} />
                    </Route>
                    <Route path="apps/user" element={<DashboardLayout lang="en" />}>
                        <Route path="list" element={<UserListApp />} />
                        <Route path="view" element={<UserViewTab />} />
                    </Route>

                    <Route path="blueprints" element={<DashboardLayout lang="en" />}>
                        <Route path="list" element={<ECommerceProductsList />} />
                        <Route path="add" element={<UserViewTab />} />
                    </Route>
                    
                    <Route path="apps/roles" element={<DashboardLayout lang="en" />}>
                        <Route index element={<RolesApp />} />
                    </Route>
                    <Route path="apps/permissions" element={<DashboardLayout lang="en" />}>
                        <Route index element={<PermissionsApp />} />
                    </Route>
                    <Route path="apps/calendar" element={<DashboardLayout lang="en" />}>
                        <Route index element={<CalendarApp />} />
                    </Route>
                    <Route path="apps/invoice" element={<DashboardLayout lang="en" />}>
                        <Route path="list" element={<InvoiceApp />} />
                        <Route path="preview/:id" element={<PreviewPage params={{}} />} />
                        <Route path="edit/:id" element={<EditPage params={{}} />} />
                        <Route path="add" element={<InvoiceAdd />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}