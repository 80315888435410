// Next Imports
import { lazy as dynamic } from 'react';

// MUI Imports
import Grid from '@mui/material/Grid'

// Component Imports
import UserLeftOverview from '@views/apps/user/view/user-left-overview'
import UserRight from '@views/apps/user/view/user-right'

import { db } from '@/app/api/fake-db/pages/pricing'

import OverViewTab from '@views/apps/user/view/user-right/overview'
import SecurityTab from '@views/apps/user/view/user-right/security'
import BillingPlans from '@views/apps/user/view/user-right/billing-plans'
import NotificationsTab from '@views/apps/user/view/user-right/notifications'
import ConnectionsTab from '@views/apps/user/view/user-right/connections'

// Vars
const tabContentList = data => ({
  overview: <OverViewTab />,
  security: <SecurityTab />,
  'billing-plans': <BillingPlans data={data} />,
  notifications: <NotificationsTab />,
  connections: <ConnectionsTab />
})

const getPricingData = () => {
  // Vars
  // const res = await fetch(`${import.meta.env.VITE_REACT_APP_API_URL}/pages/pricing`)

  // if (!res.ok) {
  //   throw new Error('Failed to fetch data')
  // }

  // return res.json()
  return db
}

const UserViewTab = () => {
  // Vars
  const data = getPricingData()

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={4} md={5}>
        <UserLeftOverview />
      </Grid>
      <Grid item xs={12} lg={8} md={7}>
        <UserRight tabContentList={tabContentList(data)} />
      </Grid>
    </Grid>
  )
}

export default UserViewTab
