'use client'

// Next Imports
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// MUI Imports
import { useTheme } from '@mui/material/styles'
import Chip from '@mui/material/Chip'

// Third-party Imports
import PerfectScrollbar from 'react-perfect-scrollbar'

// Component Imports
import { Menu, SubMenu, MenuItem, MenuSection } from '@menu/vertical-menu'

// import { GenerateVerticalMenu } from '@components/GenerateMenu'
// Hook Imports
import useVerticalNav from '@menu/hooks/useVerticalNav'
import { useSettings } from '@core/hooks/useSettings'

// Styled Component Imports
import StyledVerticalNavExpandIcon from '@menu/styles/vertical/StyledVerticalNavExpandIcon'

// Style Imports
import menuItemStyles from '@core/styles/vertical/menuItemStyles'
import menuSectionStyles from '@core/styles/vertical/menuSectionStyles'

const RenderExpandIcon = ({ open, transitionDuration }) => (
  <StyledVerticalNavExpandIcon open={open} transitionDuration={transitionDuration}>
    <i className='ri-arrow-right-s-line' />
  </StyledVerticalNavExpandIcon>
)

const VerticalMenu = ({ dictionary, scrollMenu }) => {
  // Hooks
  const theme = useTheme()
  const verticalNavOptions = useVerticalNav()
  const params = useParams()
  const { isBreakpointReached } = useVerticalNav()
  const { settings } = useSettings()

  // Vars
  const { transitionDuration } = verticalNavOptions
  // const { lang: locale, id } = params
  const id = undefined;
  const locale = "en"
  const ScrollWrapper = isBreakpointReached ? 'div' : PerfectScrollbar

  return (
    // eslint-disable-next-line lines-around-comment
    /* Custom scrollbar instead of browser scroll, remove if you want browser scroll only */
    <ScrollWrapper
      {...(isBreakpointReached
        ? {
            className: 'bs-full overflow-y-auto overflow-x-hidden',
            onScroll: container => scrollMenu(container, false)
          }
        : {
            options: { wheelPropagation: false, suppressScrollX: true },
            onScrollY: container => scrollMenu(container, true)
          })}
    >
      {/* Incase you also want to scroll NavHeader to scroll with Vertical Menu, remove NavHeader from above and paste it below this comment */}
      {/* Vertical Menu */}
      <Menu
        popoutMenuOffset={{ mainAxis: 10 }}
        menuItemStyles={menuItemStyles(verticalNavOptions, theme, settings)}
        renderExpandIcon={({ open }) => <RenderExpandIcon open={open} transitionDuration={transitionDuration} />}
        renderExpandedMenuItemIcon={{ icon: <i className='ri-circle-line' /> }}
        menuSectionStyles={menuSectionStyles(verticalNavOptions, theme)}
      >
        {/* <SubMenu
          label={dictionary['navigation'].dashboards}
          icon={<i className='ri-home-smile-line' />}
          suffix={<Chip label='3' size='small' color='error' />}
        >
          <MenuItem href={`/${locale}/dashboards/crm`}>{dictionary['navigation'].crm}</MenuItem>
          <MenuItem href={`/${locale}/dashboards/analytics`}>{dictionary['navigation'].analytics}</MenuItem>
          <MenuItem href={`/${locale}/dashboards/ecommerce`}>{dictionary['navigation'].eCommerce}</MenuItem>
        </SubMenu> */}
        
        <MenuItem href={`/${locale}/dashboards/summary`} icon={<i className='ri-home-smile-line' />}>
          {dictionary['navigation'].dashboard}
        </MenuItem>
        <MenuItem href={`/${locale}/dashboards/kanban`} icon={<i className='ri-home-smile-line' />}>
          {dictionary['navigation'].kanban}
        </MenuItem>
        <MenuItem href={`/${locale}/dashboards/chat`} icon={<i className='ri-home-smile-line' />}>
          {dictionary['navigation'].chat}
        </MenuItem>
          
        <SubMenu label={dictionary['navigation'].platformEnginePages}>
          <MenuItem href={`/${locale}/platform/overview`} icon={<i className='ri-radio-button-line' />}>
            {dictionary['navigation'].overview}
          </MenuItem>

          


          <MenuItem href={`/${locale}/platform/resources`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].resources}
          </MenuItem>

          <MenuItem href={`/${locale}/platform/blueprints`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].blueprints}
          </MenuItem>

        </SubMenu>

        <SubMenu label={dictionary['navigation'].devopsEnginePages}>
          <MenuItem href={`/${locale}/devops/overview`} icon={<i className='ri-radio-button-line' />}>
            {dictionary['navigation'].overview}
          </MenuItem>

          {/* <MenuItem href={`/${locale}/devops/connections`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].connections}
          </MenuItem> */}

          {/* <SubMenu label={dictionary['navigation'].integrations} icon={<i className='ri-git-commit-line' />}>
            <MenuItem href={`/${locale}/devops/connections/overview`}>{dictionary['navigation'].connections}</MenuItem>
            <MenuItem href={`/${locale}/devops/connections/list`}>{dictionary['navigation'].connectionslist}</MenuItem>
          </SubMenu> */}

          <MenuItem href={`/${locale}/devops/resources`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].resources}
          </MenuItem>

          <MenuItem href={`/${locale}/devops/blueprints`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].blueprints}
          </MenuItem> 

        </SubMenu>

        <SubMenu label={dictionary['navigation'].observabilityEnginePages}>
          <MenuItem href={`/${locale}/observability/overview`} icon={<i className='ri-radio-button-line' />}>
            {dictionary['navigation'].overview}
          </MenuItem>

          <MenuItem href={`/${locale}/observability/dorametrics`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].dorametrics}
          </MenuItem>

          {/* <MenuItem href={`/${locale}/observability/apm`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].integrations}
          </MenuItem> */}

          <MenuItem href={`/${locale}/observability/monitoring`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].monitoring}
          </MenuItem>

          <MenuItem href={`/${locale}/observability/logging`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].logging}
          </MenuItem> 
        </SubMenu>

        <SubMenu label={dictionary['navigation'].securityEnginePages}>
          <MenuItem href={`/${locale}/security/overview`} icon={<i className='ri-radio-button-line' />}>
            {dictionary['navigation'].overview}
          </MenuItem>

          {/* <SubMenu label={dictionary['navigation'].integrations} icon={<i className='ri-git-commit-line' />}>
            <MenuItem href={`/${locale}/security/connections/overview`}>{dictionary['navigation'].connections}</MenuItem>
            <MenuItem href={`/${locale}/security/connections/list`}>{dictionary['navigation'].connectionslist}</MenuItem>
          </SubMenu> */}

          <MenuItem href={`/${locale}/security/resources`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].resources}
          </MenuItem> 

          <MenuItem href={`/${locale}/security/blueprints`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].blueprints}
          </MenuItem> 

        </SubMenu>

        <SubMenu label={dictionary['navigation'].workflowEnginePages}>
          <MenuItem href={`/${locale}/workflows/overview`} icon={<i className='ri-radio-button-line' />}>
            {dictionary['navigation'].overview}
          </MenuItem>
          <MenuItem href={`/${locale}/workflows/schedules`} icon={<i className='ri-radio-button-line' />}>
            {dictionary['navigation'].schedules}
          </MenuItem>
          <MenuItem href={`/${locale}/workflows/triggers`} icon={<i className='ri-radio-button-line' />}>
            {dictionary['navigation'].triggers}
          </MenuItem>
        </SubMenu>

        <SubMenu label={dictionary['navigation'].costEnginePages}>
          <MenuItem href={`/${locale}/cost/overview`} icon={<i className='ri-radio-button-line' />}>
            {dictionary['navigation'].overview}
          </MenuItem>
          
          {/* <MenuItem href={`/${locale}/cost/connections`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].connections}
          </MenuItem> */}
        </SubMenu>

        <SubMenu label={dictionary['navigation'].aiEnginePages}>
          <MenuItem href={`/${locale}/ai/overview`} icon={<i className='ri-radio-button-line' />}>
            {dictionary['navigation'].overview}
          </MenuItem>

          <MenuItem href={`/${locale}/ai/blueprintgen`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].blueprintgen}
          </MenuItem>
        </SubMenu>

        <SubMenu label={dictionary['navigation'].backupEnginePages}>
          <MenuItem href={`/${locale}/backup/overview`} icon={<i className='ri-radio-button-line' />}>
            {dictionary['navigation'].overview}
          </MenuItem>

          {/* <SubMenu label={dictionary['navigation'].integrations} icon={<i className='ri-git-commit-line' />}>
            <MenuItem href={`/${locale}/backup/connections/overview`}>{dictionary['navigation'].connections}</MenuItem>
            <MenuItem href={`/${locale}/backup/connections/list`}>{dictionary['navigation'].connectionslist}</MenuItem>
          </SubMenu> */}

          <MenuItem href={`/${locale}/backup/resources`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].resources}
          </MenuItem>

          <MenuItem href={`/${locale}/backup/blueprints`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].blueprints}
          </MenuItem> 

        </SubMenu>

        <SubMenu label={dictionary['navigation'].blueprintEngine}>
            <MenuItem href={`/${locale}/blueprints/list`}>{dictionary['navigation'].list}</MenuItem>
            <MenuItem href={`/${locale}/blueprints/add`}>{dictionary['navigation'].add}</MenuItem>
        </SubMenu>

        <SubMenu label={dictionary['navigation'].integrationEngine}>
            <MenuItem href={`/${locale}/platform/connections/overview`}>{dictionary['navigation'].connections}</MenuItem>
            <MenuItem href={`/${locale}/platform/connections/list`}>{dictionary['navigation'].connectionslist}</MenuItem>
        </SubMenu>

        <SubMenu label={dictionary['navigation'].settingsPages}>
          <MenuItem href={`/${locale}/platform/environments`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].environments}
          </MenuItem>

          <MenuItem href={`/${locale}/pages/user-profile`} icon={<i className='ri-circle-line' />}>
            {dictionary['navigation'].userProfile}
          </MenuItem>
          
          <SubMenu label={dictionary['navigation'].user} icon={<i className='ri-user-line' />}>
            <MenuItem href={`/${locale}/apps/user/list`}>{dictionary['navigation'].list}</MenuItem>
            <MenuItem href={`/${locale}/apps/user/view`}>{dictionary['navigation'].view}</MenuItem>
          </SubMenu>

          <SubMenu label={dictionary['navigation'].rolesPermissions} icon={<i className='ri-lock-2-line' />}>
            <MenuItem href={`/${locale}/apps/roles`}>{dictionary['navigation'].roles}</MenuItem>
            <MenuItem href={`/${locale}/apps/permissions`}>{dictionary['navigation'].permissions}</MenuItem>
          </SubMenu>

          <MenuItem href={`/${locale}/apps/calendar`} icon={<i className='ri-calendar-line' />}>
            {dictionary['navigation'].calendar}
          </MenuItem>
          
          <MenuItem href={`/${locale}/pages/account-settings`} icon={<i className='ri-circle-line' />}>
            {dictionary['navigation'].accountSettings}
          </MenuItem>

          <MenuItem href={`/${locale}/pages/pricing`} icon={<i className='ri-circle-line' />}>
            {dictionary['navigation'].pricing}
          </MenuItem>
          
          <SubMenu label={dictionary['navigation'].invoice} icon={<i className='ri-bill-line' />}>
            <MenuItem href={`/${locale}/apps/invoice/list`}>{dictionary['navigation'].list}</MenuItem>
            <MenuItem href={`/${locale}/apps/invoice/preview/${id || '4987'}`}>
              {dictionary['navigation'].preview}
            </MenuItem>
            <MenuItem href={`/${locale}/apps/invoice/edit/${id || '4987'}`}>{dictionary['navigation'].edit}</MenuItem>
            <MenuItem href={`/${locale}/apps/invoice/add`}>{dictionary['navigation'].add}</MenuItem>
          </SubMenu>

          <MenuItem href={`/${locale}/pages/faq`} icon={<i className='ri-circle-line' />}>{dictionary['navigation'].faq}</MenuItem>
            
          
        </SubMenu>


        {/* <MenuSection label={dictionary['navigation'].appsPages}>
          <SubMenu label={dictionary['navigation'].pages} icon={<i className='ri-layout-left-line' />}>
            <SubMenu label={dictionary['navigation'].miscellaneous}>
              <MenuItem href={`/${locale}/pages/misc/coming-soon`} target='_blank'>
                {dictionary['navigation'].comingSoon}
              </MenuItem>
              <MenuItem href={`/${locale}/pages/misc/under-maintenance`} target='_blank'>
                {dictionary['navigation'].underMaintenance}
              </MenuItem>
              <MenuItem href={`/${locale}/pages/misc/404-not-found`} target='_blank'>
                {dictionary['navigation'].pageNotFound404}
              </MenuItem>
              <MenuItem href={`/${locale}/pages/misc/401-not-authorized`} target='_blank'>
                {dictionary['navigation'].notAuthorized401}
              </MenuItem>
            </SubMenu>
          </SubMenu>
          <SubMenu label={dictionary['navigation'].authPages} icon={<i className='ri-shield-keyhole-line' />}>
            <SubMenu label={dictionary['navigation'].login}>
              <MenuItem href={`/${locale}/pages/auth/login-v1`} target='_blank'>
                {dictionary['navigation'].loginV1}
              </MenuItem>
              <MenuItem href={`/${locale}/pages/auth/login-v2`} target='_blank'>
                {dictionary['navigation'].loginV2}
              </MenuItem>
            </SubMenu>
            <SubMenu label={dictionary['navigation'].register}>
              <MenuItem href={`/${locale}/pages/auth/register-v1`} target='_blank'>
                {dictionary['navigation'].registerV1}
              </MenuItem>
              <MenuItem href={`/${locale}/pages/auth/register-v2`} target='_blank'>
                {dictionary['navigation'].registerV2}
              </MenuItem>
              <MenuItem href={`/${locale}/pages/auth/register-multi-steps`} target='_blank'>
                {dictionary['navigation'].registerMultiSteps}
              </MenuItem>
            </SubMenu>
            <SubMenu label={dictionary['navigation'].verifyEmail}>
              <MenuItem href={`/${locale}/pages/auth/verify-email-v1`} target='_blank'>
                {dictionary['navigation'].verifyEmailV1}
              </MenuItem>
              <MenuItem href={`/${locale}/pages/auth/verify-email-v2`} target='_blank'>
                {dictionary['navigation'].verifyEmailV2}
              </MenuItem>
            </SubMenu>
            <SubMenu label={dictionary['navigation'].forgotPassword}>
              <MenuItem href={`/${locale}/pages/auth/forgot-password-v1`} target='_blank'>
                {dictionary['navigation'].forgotPasswordV1}
              </MenuItem>
              <MenuItem href={`/${locale}/pages/auth/forgot-password-v2`} target='_blank'>
                {dictionary['navigation'].forgotPasswordV2}
              </MenuItem>
            </SubMenu>
            <SubMenu label={dictionary['navigation'].resetPassword}>
              <MenuItem href={`/${locale}/pages/auth/reset-password-v1`} target='_blank'>
                {dictionary['navigation'].resetPasswordV1}
              </MenuItem>
              <MenuItem href={`/${locale}/pages/auth/reset-password-v2`} target='_blank'>
                {dictionary['navigation'].resetPasswordV2}
              </MenuItem>
            </SubMenu>
            <SubMenu label={dictionary['navigation'].twoSteps}>
              <MenuItem href={`/${locale}/pages/auth/two-steps-v1`} target='_blank'>
                {dictionary['navigation'].twoStepsV1}
              </MenuItem>
              <MenuItem href={`/${locale}/pages/auth/two-steps-v2`} target='_blank'>
                {dictionary['navigation'].twoStepsV2}
              </MenuItem>
            </SubMenu>
          </SubMenu>
          <SubMenu label={dictionary['navigation'].wizardExamples} icon={<i className='ri-git-commit-line' />}>
            <MenuItem href={`/${locale}/pages/wizard-examples/checkout`}>{dictionary['navigation'].checkout}</MenuItem>
            <MenuItem href={`/${locale}/pages/wizard-examples/property-listing`}>
              {dictionary['navigation'].propertyListing}
            </MenuItem>
            <MenuItem href={`/${locale}/pages/wizard-examples/create-deal`}>
              {dictionary['navigation'].createDeal}
            </MenuItem>
          </SubMenu>
          <MenuItem href={`/${locale}/pages/dialog-examples`} icon={<i className='ri-tv-2-line' />}>
            {dictionary['navigation'].dialogExamples}
          </MenuItem>
          <SubMenu label={dictionary['navigation'].widgetExamples} icon={<i className='ri-bar-chart-box-line' />}>
            <MenuItem href={`/${locale}/pages/widget-examples/advanced`}>{dictionary['navigation'].advanced}</MenuItem>
            <MenuItem href={`/${locale}/pages/widget-examples/statistics`}>
              {dictionary['navigation'].statistics}
            </MenuItem>
            <MenuItem href={`/${locale}/pages/widget-examples/charts`}>{dictionary['navigation'].charts}</MenuItem>
            <MenuItem href={`/${locale}/pages/widget-examples/gamification`}>
              {dictionary['navigation'].gamification}
            </MenuItem>
          </SubMenu>
          <MenuItem href={`/${locale}/icons-test`} icon={<i className='ri-remixicon-line' />}>
            Icons Test
          </MenuItem>
        </MenuSection>
        <MenuSection label={dictionary['navigation'].formsAndTables}>
          <MenuItem href={`/${locale}/forms/form-layouts`} icon={<i className='ri-layout-4-line' />}>
            {dictionary['navigation'].formLayouts}
          </MenuItem>
          <MenuItem href={`/${locale}/forms/form-validation`} icon={<i className='ri-checkbox-multiple-line' />}>
            {dictionary['navigation'].formValidation}
          </MenuItem>
          <MenuItem href={`/${locale}/forms/form-wizard`} icon={<i className='ri-git-commit-line' />}>
            {dictionary['navigation'].formWizard}
          </MenuItem>
          <MenuItem href={`/${locale}/react-table`} icon={<i className='ri-table-alt-line' />}>
            {dictionary['navigation'].reactTable}
          </MenuItem>
          <MenuItem
            href={`/docs/user-interface/form-elements/intro`}
            suffix={<i className='ri-external-link-line text-xl' />}
            target='_blank'
            icon={<i className='ri-radio-button-line' />}
          >
            {dictionary['navigation'].formELements}
          </MenuItem>
          <MenuItem
            href={`/docs/user-interface/mui-table`}
            suffix={<i className='ri-external-link-line text-xl' />}
            target='_blank'
            icon={<i className='ri-table-2' />}
          >
            {dictionary['navigation'].muiTables}
          </MenuItem>
        </MenuSection>
        <MenuSection label={dictionary['navigation'].chartsMisc}>
          <SubMenu label={dictionary['navigation'].charts} icon={<i className='ri-bar-chart-2-line' />}>
            <MenuItem href={`/${locale}/charts/recharts`}>{dictionary['navigation'].recharts}</MenuItem>
            <MenuItem href={`/${locale}/charts/apex-charts`}>{dictionary['navigation'].apex}</MenuItem>
          </SubMenu>
          <MenuItem
            href={`/docs/user-interface/typography`}
            suffix={<i className='ri-external-link-line text-xl' />}
            target='_blank'
            icon={<i className='ri-pantone-line' />}
          >
            {dictionary['navigation'].userInterface}
          </MenuItem>
          <MenuItem
            href={`/docs/user-interface/components/intro`}
            suffix={<i className='ri-external-link-line text-xl' />}
            target='_blank'
            icon={<i className='ri-toggle-line' />}
          >
            {dictionary['navigation'].components}
          </MenuItem>
          <MenuItem
            href={`/docs/menu-examples/intro`}
            suffix={<i className='ri-external-link-line text-xl' />}
            target='_blank'
            icon={<i className='ri-menu-search-line' />}
          >
            {dictionary['navigation'].menuExamples}
          </MenuItem>
          <MenuItem
            href='https://themeselection.com/support'
            suffix={<i className='ri-external-link-line text-xl' />}
            target='_blank'
            icon={<i className='ri-lifebuoy-line' />}
          >
            {dictionary['navigation'].raiseSupport}
          </MenuItem>
          <MenuItem
            href='https://demos.themeselection.com/marketplace/materio-mui-nextjs-admin-template/documentation'
            suffix={<i className='ri-external-link-line text-xl' />}
            target='_blank'
            icon={<i className='ri-book-line' />}
          >
            {dictionary['navigation'].documentation}
          </MenuItem>
          <SubMenu label={dictionary['navigation'].others} icon={<i className='ri-more-line' />}>
            <MenuItem suffix={<Chip label='New' size='small' color='info' />}>
              {dictionary['navigation'].itemWithBadge}
            </MenuItem>
            <MenuItem
              href='https://mui.com/store/contributors/themeselection'
              target='_blank'
              suffix={<i className='ri-external-link-line text-xl' />}
            >
              {dictionary['navigation'].externalLink}
            </MenuItem>
            <SubMenu label={dictionary['navigation'].menuLevels}>
              <MenuItem>{dictionary['navigation'].menuLevel2}</MenuItem>
              <SubMenu label={dictionary['navigation'].menuLevel2}>
                <MenuItem>{dictionary['navigation'].menuLevel3}</MenuItem>
                <MenuItem>{dictionary['navigation'].menuLevel3}</MenuItem>
              </SubMenu>
            </SubMenu>
            <MenuItem disabled>{dictionary['navigation'].disabledMenu}</MenuItem>
          </SubMenu>
        </MenuSection> */}
      </Menu>
      {/* <Menu
          popoutMenuOffset={{ mainAxis: 10 }}
          menuItemStyles={menuItemStyles(verticalNavOptions, theme, settings)}
          renderExpandIcon={({ open }) => <RenderExpandIcon open={open} transitionDuration={transitionDuration} />}
          renderExpandedMenuItemIcon={{ icon: <i className='ri-circle-line' /> }}
          menuSectionStyles={menuSectionStyles(verticalNavOptions, theme)}
        >
          <GenerateVerticalMenu menuData={menuData(dictionary, params)} />
        </Menu> */}
    </ScrollWrapper>
  )
}

export default VerticalMenu
