const DirectionRtl = props => {
  return (
    <svg width='104' height='66' viewBox='0 0 104 66' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='104' height='66' rx='4' fill='currentColor' fillOpacity='0.02' />
      <rect x='73.4756' y='4.12134' width='24.0976' height='57.5885' rx='2' fill='currentColor' fillOpacity='0.08' />
      <rect
        x='78.5986'
        y='16.8697'
        width='13.8545'
        height='2.0921'
        rx='1.04605'
        fill='currentColor'
        fillOpacity='0.3'
      />
      <rect
        x='82.5713'
        y='25.5618'
        width='9.87943'
        height='2.0921'
        rx='1.04605'
        fill='currentColor'
        fillOpacity='0.3'
      />
      <rect
        x='80.0693'
        y='34.2537'
        width='12.3826'
        height='2.0921'
        rx='1.04605'
        fill='currentColor'
        fillOpacity='0.3'
      />
      <rect
        x='86.3633'
        y='42.9459'
        width='6.08818'
        height='2.0921'
        rx='1.04605'
        fill='currentColor'
        fillOpacity='0.3'
      />
      <rect
        x='84.3613'
        y='51.6382'
        width='8.09094'
        height='2.0921'
        rx='1.04605'
        fill='currentColor'
        fillOpacity='0.3'
      />
      <rect x='5.20215' y='4.12146' width='62.3885' height='57.5885' rx='2' fill='currentColor' fillOpacity='0.08' />
      <rect x='45.709' y='14.1833' width='13.8545' height='2.0921' rx='1.04605' fill='currentColor' fillOpacity='0.3' />
      <rect
        x='26.7617'
        y='22.8754'
        width='32.8013'
        height='2.0921'
        rx='1.04605'
        fill='currentColor'
        fillOpacity='0.3'
      />
      <rect
        x='18.3555'
        y='31.5674'
        width='41.2076'
        height='2.0921'
        rx='1.04605'
        fill='currentColor'
        fillOpacity='0.3'
      />
      <rect
        x='26.7617'
        y='40.2594'
        width='32.8013'
        height='2.0921'
        rx='1.04605'
        fill='currentColor'
        fillOpacity='0.3'
      />
      <rect
        x='53.7881'
        y='48.9517'
        width='5.77482'
        height='2.0921'
        rx='1.04605'
        fill='currentColor'
        fillOpacity='0.3'
      />
    </svg>
  )
}

export default DirectionRtl
