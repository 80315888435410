// Third-party Imports
// import 'server-only'

// const dictionaries = {
//   en: () => import('@/data/dictionaries/en.json').then(module => module.default),
//   fr: () => import('@/data/dictionaries/fr.json').then(module => module.default),
//   ar: () => import('@/data/dictionaries/ar.json').then(module => module.default)
// }

import en from '@/data/dictionaries/en.json'
import fr from '@/data/dictionaries/fr.json'
import ar from '@/data/dictionaries/ar.json'

const dictionaries = {
  en: en,
  fr: fr,
  ar: ar
}

export const getDictionary = locale => dictionaries[locale]
